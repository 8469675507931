import React from "react";
import { useStateContext } from "../context/ContextProvider";
import { listBCalientes } from "../data/dummy";

const TabMenuBebidasC = () => {
  const { isActiveTab } = useStateContext();
  return (
    <div
      className={` w-full flex-col ${isActiveTab === 7 ? "flex" : "hidden"}`}
    >
      <div
        className={`${
          window.screen.availHeight >= 1900 ? "gap-16" : "gap-2"
        } overflow-hidden flex flex-col   h-full`}
      >
        {isActiveTab === 7 &&
          listBCalientes?.map((item, index) => (
            <div
              style={
                index === 0
                  ? { animationDuration: "300ms" }
                  : { animationDuration: index * 400 + "ms" }
              }
              className={`bg-gray-500/10 w-full text-center lg:text-left animate-fadeInTop lg:animate-fadeInLeft p-2 flex flex-col lg:flex-row gap-2 items-center cursor-pointer hover:bg-gray-500/20`}
              key={index}
            >
              <img
                className="w-52 h-52 lg:w-44 lg:h-44 object-cover"
                src={item.image}
                alt=""
              />
              <div className="flex flex-col gap-3">
                <h2
                  className="text-3xl font-semibold"
                  style={{ color: item.nameColor }}
                >
                  {item.name}
                </h2>
                <p className="tracking-wide ">{item.description}</p>
                {/* <b className=" text-red-header  text-xs lg:text-right lg:w-3/4 lg:self-end">
                  
                </b> */}
              </div>
              <span className="border-b border-gray-400 my-4 w-full lg:hidden"></span>
            </div>
          ))}
      </div>
    </div>
  );
};

export default TabMenuBebidasC;
