import React from "react";
import { useEffect, useState, useRef } from "react";
import { BtnWhatsApp, Footer, NavBarSalon, TopHeader } from "../components";
import emailjs from "@emailjs/browser";
import { useStateContext } from "../context/ContextProvider";
import ButtonBuble from "../components/ButtonBuble";

import titleRed from "../data/paquetes/tex_nuestrospaquetes.png";
import titleBlue from "../data/paquetes/tex_nuestrospaquetes2.png";
import pepepkaz from "../data/paquetes/paquete_pkaz.png";
import superPepepkaz from "../data/paquetes/paquete_superpkaz.png";
import taquizas from "../data/paquetes/paquete_supertaquizas.png";
import tex_pkaz from "../data/paquetes/tex_pkaz.png";
import tex_superpkaz from "../data/paquetes/tex_superpkaz.png";
import tex_supertaquizas from "../data/paquetes/tex_supertaquizas.png";
// import paquete_otros from "../data/paquetes/paquete_otros.png";
// import paquete_servespeciales from "../data/paquetes/paquete_servespeciales.png";
// import tex_complementatupaquete from "../data/paquetes/tex_complementatupaquete.png";
// import tex_armatupaqute from "../data/paquetes/tex_armatupaqute.png";
import tex_cotizacion from "../data/paquetes/tex_cotizacion.png";
import { NavLink } from "react-router-dom";

const initialForm = {
  mail: "",
  tel: "",
  whatsapp: "",
  totalPpl: "",
};

const validationsForm = (form) => {
  let errors = {};
  let regexEmail = /^(\w+[/./-/_]?){1,}@[a-z]+[/.]\w{2,}$/;

  if (!form.mail.trim()) {
    errors.mail = "";
  } else if (!regexEmail.test(form.mail.trim())) {
    errors.mail = "El campo 'Email' es incorrecto";
  }
  return errors;
};

const Paquetes = () => {
  const { setBgHeaders, scrollTop } = useStateContext();
  useEffect(() => {
    setBgHeaders(true);
  }, []);

  const [form, setForm] = useState(initialForm);
  const [errors, setErrors] = useState({});
  const [response, setResponse] = useState(null);
  const [responseError, setResponseError] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setForm({
      ...form,
      [name]: value,
    });
  };
  const handleBlur = (e) => {
    handleChange(e);
    setErrors(validationsForm(form));
  };
  const formRefPaquete = useRef();

  const sendEmail = async (e) => {
    e.preventDefault();

    const url = "https://api.emailjs.com/api/v1.0/email/send";
    const controller = new AbortController();
    const signal = controller.signal;

    const fail = () => {
      setResponseError(true);
      setTimeout(() => setResponseError(false), 3500);
    };
    emailjs
      .sendForm(
        "service_wvh2kct",
        "template_2i5tbpn",
        formRefPaquete.current,
        "WxIRrOEY9dImu_pmI"
      )
      .then(
        (result) => {
          setResponse(true);
          setForm(initialForm);
          setTimeout(() => setResponse(false), 3500);
        },
        (error) => {
          fail();
        }
      );
  };

  return (
    <>
      <TopHeader phone={+525529911638} textWhatsApp="55 2991 1638" />
      <BtnWhatsApp phone={+525529911638} />
      <NavBarSalon />
      <div className="pt-48 bg-BgMenu md:bg-cover flex flex-col gap-14 lg:gap-20 pb-20 lg:pb-32">
        <div className="flex flex-col justify-center items-center px-4 gap-2 md:w-4/5 lg:w-3/5 m-auto">
          <img src={titleRed} alt="" />
          <img src={titleBlue} alt="" />
          <b className="text-[#ff1643] md:text-2xl">
            Selecciona el paquete de tu elección
          </b>
        </div>

        <div className="grid gap-14 lg:grid-cols-3 lg:gap-6 lg:px-6">
          <div className="flex flex-col items-center">
            <img
              style={{ animationDelay: ".2s" }}
              className="w-2/3 md:w-2/5 lg:w-3/4 animate-wiggleSlow "
              src={pepepkaz}
              alt=""
            />
            <div className="bg-[#0056b3] lg:max-h-[25rem] lg:min-h-[28rem] pt-5 pb-10 shadow-sm shadow-black relative rounded-2xl w-80 p-2 flex flex-col gap-2 items-center hover:-translate-y-2 duration-500">
              <p className="px-4 text-white">
                ANIMADOR / APOYO 1 (COCINA) / APOYO 2 (MESA) / APOYO 3 (MESA) /
                MONITOREO DIGITAL CERRADO / COCINETA / LIMPIEZA / TIROLESA /
                ACCESO A JUEGOS / DINAMICAS NIÑOS Y ADULTOS / 30 INVITACIONES /
                1 BANNER TEMATICO
              </p>
              <NavLink
                onClick={scrollTop}
                to="/contacto"
                className="absolute bottom-0 translate-y-1/2 hover:translate-y-1/5 duration-300"
              >
                <ButtonBuble
                  bubleColor="#0056b3"
                  btnColor="#f3dd09"
                  text="¡Reservar!"
                  textC="#0056b3"
                  border={true}
                  rounded="0.5rem"
                  borderC="#0056b3"
                  hoverColor="white"
                  hoverBorderColor="white"
                />
              </NavLink>
            </div>
          </div>
          <div className="flex flex-col items-center">
            <img
              style={{ animationDelay: ".5s" }}
              className="w-2/3 md:w-2/5 lg:w-3/4 animate-wiggleSlow "
              src={superPepepkaz}
              alt=""
            />
            <div className="bg-[#ff1643] lg:max-h-[25rem] lg:min-h-[28rem] pt-5 shadow-sm shadow-black pb-10 relative rounded-2xl w-80 p-2 flex flex-col gap-2 items-center hover:-translate-y-2 duration-500">
              <p className="px-4 lg:max-h-[21rem] overflow-y-auto text-white">
                ANIMADOR / APOYO 1 (COCINA) / APOYO 2 (MESA) / APOYO 3 (MESA) /
                MONITOREO DIGITAL CERRADO / COCINETA / LIMPIEZA / TIROLESA /
                ACCESO A JUEGOS / DINAMICAS NIÑOS Y ADULTOS / 30 INVITACIONES /
                1 BANNER TEMATICO / TAQUIZA (4 GUISADOS, ARROZ Y FRIJOLES) /
                PASTEL 3 LECHES / REFRESCO (5 BOTELLAS DE 3LTS) / AGUA (2
                GARRAFONES SABOR 1 GARRAFON NATURAL) / MENU INFANTIL /
              </p>
              <NavLink
                onClick={scrollTop}
                to="/contacto"
                className="absolute bottom-0 translate-y-1/2 hover:translate-y-1/5 duration-300"
              >
                <ButtonBuble
                  bubleColor="#ff1643"
                  btnColor="#0056b3"
                  text="¡Reservar!"
                  textC="white"
                  border={true}
                  rounded="0.5rem"
                  borderC="white"
                  hoverColor="#fff"
                />
              </NavLink>
            </div>
          </div>
          <div className="flex flex-col items-center">
            <img
              style={{ animationDelay: "1s" }}
              className="w-2/3 md:w-2/5 lg:w-3/4 animate-wiggleSlow "
              src={taquizas}
              alt=""
            />
            <div className="bg-[#ffdb01] lg:max-h-[25rem] lg:min-h-[28rem] pt-5 shadow-sm shadow-black  pb-10 relative rounded-2xl w-80 p-2 flex flex-col gap-2 items-center hover:-translate-y-2 duration-500">
              <p className="px-4  overflow-y-auto text-[#0056b3]">
                ANIMADOR / APOYO 1 (COCINA) / APOYO 2 (MESA) / APOYO 3 (MESA) /
                MONITOREO DIGITAL CERRADO / COCINETA / LIMPIEZA / TIROLESA /
                ACCESO A JUEGOS / DINAMICAS NIÑOS Y ADULTOS / 30 INVITACIONES /
                1 BANNER TEMATICO / TAQUIZA (8 GUISADOS, SALSAS, TORTILLAS,
                ARROZ Y FRIJOLES) / PASTEL TEMATICO / REFRESCO (10 BOTELLAS DE
                3LTS) / AGUA (3 GARRAFONES SABOR 1 GARRAFON NATURAL) / MENU
                INFANTIL / HIELO (2 BOLSAS) / SHOW PAYASO ó MAGO / PIÑATA A
                ELEGIR (SIN DULCES) / PREMIOS / PEPE VASOS (50 PZS)
              </p>
              <NavLink
                onClick={scrollTop}
                to="/contacto"
                className="absolute bottom-0 translate-y-1/2 hover:translate-y-1/5 duration-300"
              >
                <ButtonBuble
                  bubleColor="#ffdb01"
                  btnColor="#ff1643"
                  text="¡Reservar!"
                  textC="white"
                  border={true}
                  rounded="0.5rem"
                  borderC="#ffdb01"
                  hoverBorderColor="#0056b3"
                  hoverColor="#0056b3"
                />
              </NavLink>
            </div>
          </div>
        </div>

        <div
          className="mt-5 bg-[#0056b3] overflow-hidden w-11/12 md:w-8/12 lg:w-7/12 m-auto 
          p-4 group rounded-2xl text-[#fff] relative shadow-sm cursor-default shadow-gray-900

          before:bg-[#ff1643] before:w-72 before:h-72 before:-translate-y-1/2 
          before:hover:-translate-y-1/3 before:duration-[5000ms] before:hover:-translate-x-1/3
           before:-translate-x-1/2 before:rounded-full before:absolute before:z-10 before:-top-0 before:left-0

          after:bg-[#ffdb01] after:w-72 after:h-72 after:translate-y-1/2 
          after:hover:translate-y-1/3 after:duration-[3000ms] after:hover:translate-x-1/3 
          after:translate-x-1/2 after:rounded-full after:absolute after:z-10 after:-bottom-12 after:-right-12
        "
        >
          <div className="relative z-20">
            <p>
              La cocineta incluye prestamo de jarras, hieleras, pinzas, horno de
              micoondas, cafetera y buffetera de 2 espacios
            </p>
            <p>La limpieza es exclusiva al comienzo y termino del evento</p>
            <p>
              El acceso a juegos es para adultos y niños (para tirolesa niños
              mayores de 3 años)
            </p>
            <p>El desechable es exclusivo para la taquiza y el pastel</p>
          </div>
        </div>

        <div className="flex flex-col p-3 md:w-3/5 m-auto">
          <img src={tex_cotizacion} alt="" />
          <b className="text-[#fe1439] text-md text-center mb-3 lg:mb-8 md:text-xl">
            Estás a un paso de terminar esta aventura en Pepe PKAZ
          </b>
          <form
            ref={formRefPaquete}
            onSubmit={sendEmail}
            className="items-center flex flex-col gap-4"
          >
            <div className="relative group flex w-full">
              {errors.mail && (
                <p className="absolute top-0.5 right-2 group-hover:text-white text-[#ff6c99] text-xs md:text-md">
                  {errors.mail}
                </p>
              )}
              <input
                required
                onBlur={handleBlur}
                onChange={handleChange}
                value={form.mail}
                name="mail"
                type="email"
                className="border-2 w-full text-center font-bold
               hover:border-white duration-500 text-xs md:text-md hover:text-white hover:placeholder:text-white placeholder:text-[#0058b8] outline-[#0058b8] hover:bg-[#0058b8] border-[#0058b8] p-2 text-[#0058b8] rounded-xl"
                placeholder="CORREO ELECTRÓNICO"
              />
            </div>

            <input
              onBlur={handleBlur}
              onChange={handleChange}
              value={form.tel}
              name="tel"
              type="tel"
              placeholder="dejanos tu teléfono un asesor te marcara"
              className="uppercase text-xs md:text-md duration-500 border-2 w-full text-center font-bold hover:bg-transparent hover:placeholder:text-[#ff1643] outline-[#ff1643] placeholder:text-white hover:text-[#ff1643] border-[#ff1643] p-2 text-white bg-[#ff1643] rounded-xl"
            />

            <input
              onBlur={handleBlur}
              onChange={handleChange}
              value={form.whatsapp}
              name="whatsapp"
              type="tel"
              placeholder="dejanos tu WhatsApp"
              className="uppercase duration-500 border-2 w-full text-center font-bold
               border-[#ffdb01] text-xs md:text-md bg-[#ffdb01] hover:bg-transparent hover:text-[#ffdb01] outline-[#ffdb01] p-2 text-[#0058b8] rounded-xl"
            />
            <input
              required
              onBlur={handleBlur}
              onChange={handleChange}
              value={form.totalPpl}
              name="totalPpl"
              type="number"
              className="uppercase border-2 w-full text-center font-bold
               hover:border-white duration-500 text-xs md:text-md hover:text-white hover:placeholder:text-white placeholder:text-[#0058b8] outline-[#0058b8] hover:bg-[#0058b8] border-[#0058b8] p-2 text-[#0058b8] rounded-xl"
              placeholder="cantidad de personas"
            />
            <div className="flex flex-col w-full gap-4">
              <input
                type="submit"
                value="Enviar"
                className="uppercase text-xs md:text-md duration-500 border-2 w-full text-center font-bold
                border-green-600 bg-transparent hover:bg-green-600  hover:text-white hover:border-white cursor-pointer p-2 text-green-600  rounded-xl
              "
              />
              <p
                className={`${
                  responseError ? "bg-red-600" : "bg-green-600"
                } rounded-xl text-white text-center duration-500 p-2 ${
                  response ? "opacity-100" : "opacity-0"
                }`}
              >
                {responseError ? "Envío fallido" : "Envío exitoso"}
              </p>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Paquetes;
