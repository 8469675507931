import React from "react";
import TextotosBanner from "../data/texto_campesina.png";
import Pizza from "../data/im_pizza.png";
import TextPizza from "../data/tex_pizza.png";
import Ingredients from "../data/tex_ingredientes.png";
import Precio from "../data/texto_precio.png";

const RedBanner = () => {
  return (
    <div className="h-64 sm:h-96 lg:h-91vh bg-red-header overflow-hidden">
      <div className="relative flex items-center bg-bannerOrnamentsRedB bg-cover bg-no-repeat w-full h-full ">
        <div className="absolute flex justify-end bottom-0  px-4 w-full z-10">
          <img
            className="lg:w-5/12 bg-red-header/80 sm:hover:-translate-x-4 duration-500"
            src={Ingredients}
            alt=""
          />
        </div>
        <div className="absolute flex justify-end bottom-14 px-4 sm:px-12 sm:bottom-20 lg:right-52 w-full z-10">
          <img
            className="w-4/12 sm:w-3/12 lg:w-2/12 animate-price"
            src={Precio}
            alt=""
          />
        </div>
        <div className="absolute flex justify-start top-0 w-full z-10 px-4 sm:ml-8 lg:ml-44">
          <img
            className="w-5/12 sm:w-4/12 lg:w-2/6 animate-moveBot"
            src={TextotosBanner}
            alt=""
          />
        </div>
        <div className="absolute flex justify-end -top-4 lg:-top-8 w-full z-10">
          <img
            className="w-5/12 sm:w-4/12 lg:w-1/5 lg:mr-52 animate-zoomLeftB"
            src={TextPizza}
            alt=""
          />
        </div>
        <div className="absolute top-26 w-full">
          <img
            className="m-auto mr-4 w-4/5 sm:w-3/5 sm:mr-20 lg:w-3/6 lg:mr-64"
            src={Pizza}
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default RedBanner;
