import React, { useEffect, useState } from "react";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import Banner from "../data/img_promo.png";

const MenuBanner = () => {
  const [selectedImage, setSelectedImage] = useState(0);

  const allImages = [Banner, Banner, Banner];

  useEffect(() => {
    const timer = setInterval(() => {
      const newSlideIndex =
        selectedImage >= allImages.length - 1 ? 0 : selectedImage + 1;
      setSelectedImage(newSlideIndex);
    }, 3000);
    return () => clearInterval(timer);
  }, [selectedImage]);

  return (
    <div className="w-full my-2 md:my-6 h-full flex justify-center ">
      <div className="w-full md:w-4/6 relative flex  items-center overflow-hidden">
        <button
          onClick={() =>
            selectedImage > 0 && setSelectedImage(selectedImage - 1)
          }
          className="bg-white/50 text-red-header p-0.5 md:p-2 hover:bg-white/80 duration-300 absolute left-0"
        >
          <NavigateBeforeIcon />
        </button>
        {allImages.map((imageUrl, index) => (
          <img
            key={index}
            src={imageUrl}
            alt="banner"
            className="w-full h-full shrink-0 right-0 duration-1000"
            style={{
              backgroundImage: `url(${imageUrl})`,
              marginLeft: index === 0 ? `-${selectedImage * 100}%` : undefined,
            }}
          />
        ))}
        <button
          onClick={() =>
            selectedImage < allImages.length - 1 &&
            setSelectedImage(selectedImage + 1)
          }
          className="bg-white/50 text-red-header p-0.5 md:p-2 hover:bg-white/80 duration-300 absolute right-0"
        >
          <NavigateNextIcon />
        </button>
      </div>
    </div>
  );
};

export default MenuBanner;
