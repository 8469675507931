import React from "react";
import { Link } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";
import Pizzas from "../data/img_pizza.png";
import TextPizzas from "../data/tex_pizzas.png";
import Postres from "../data/img_postres.png";
import TextPostres from "../data/tex_postres.png";
import Bebidas from "../data/img_bebidas.png";
import TextBebidas from "../data/tex_bebidas.png";
import { useStateContext } from "../context/ContextProvider";

const YellowBanner = () => {
  const { handleClickTop } = useStateContext();

  return (
    <div className="w-full  bg-bannerOrnamentsYellowB px-8 py-12  ">
      <div className="w-full grid lg:grid-cols-3 gap-8">
        <Link
          to="/menu"
          onClick={() => handleClickTop(4)}
          className="w-full justify-start items-center flex flex-col hover:scale-105 group duration-500 group-hover:animate-wiggleSlow"
        >
          <img
            className="w-8/12 sm:w-5/12 lg:w-7/12 group-hover:animate-wiggleSlow"
            src={TextPizzas}
            alt=""
          />
          <img
            className="w-8/12 sm:w-5/12 lg:w-8/12 group-hover:animate-wiggle"
            src={Pizzas}
            alt=""
          />
          <b className="text-[#203448] px-4 text-center relative lg:top-2 group-hover:animate-wiggleSlow">
            Diferentes tipos para diferentes estilos
          </b>
        </Link>
        <Link
          to="/menu"
          onClick={() => handleClickTop(5)}
          className="w-full justify-start items-center flex flex-col hover:scale-105 group duration-500 group-hover:animate-wiggleSlow"
        >
          <img
            className="w-10/12 sm:w-6/12 lg:w-8/12 group-hover:animate-wiggleSlow"
            src={TextPostres}
            alt=""
          />
          <img
            className="w-8/12 sm:w-5/12 lg:w-8/12 group-hover:animate-wiggle"
            src={Postres}
            alt=""
          />
          <b className="text-[#203448] px-4 text-center  group-hover:animate-wiggleSlow">
            Disfruta de nuestros postres en familia
          </b>
        </Link>
        <Link
          to="/menu"
          onClick={() => handleClickTop(7)}
          className="w-full justify-start items-center flex flex-col hover:scale-105 group duration-500 group-hover:animate-wiggleSlow"
        >
          <img
            className="w-8/12 sm:w-5/12 lg:w-7/12 relative group-hover:animate-wiggleSlow"
            src={TextBebidas}
            alt=""
          />
          <img
            className="w-8/12 sm:w-5/12 lg:w-8/12  group-hover:animate-wiggle"
            src={Bebidas}
            alt=""
          />
          <b className="text-[#203448] text-center  group-hover:animate-wiggleSlow">
            Bebidas calientes y refrescantes a tu alcance
          </b>
        </Link>
      </div>
      <div className="w-full flex items-center justify-center mt-14">
        <Link
          onClick={() => scroll.scrollTo(0)}
          to="/menu"
          className="uppercase shadow-md shadow-gray-600 hover:tracking-widest duration-300 font-bold text-[#0081f8] border-4 border-[#0081f8] p-2"
        >
          ver menu
        </Link>
      </div>
    </div>
  );
};

export default YellowBanner;
