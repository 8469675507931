import React from "react";
import { useStateContext } from "../context/ContextProvider";
import { listPizzas, extraPizza } from "../data/dummy";

const TabMenuPizzas = () => {
  const { isActiveTab } = useStateContext();
  return (
    <div
      className={` w-full flex-col ${isActiveTab === 4 ? "flex" : "hidden"}`}
    >
      <div
        className={`${
          window.screen.availHeight >= 1900 ? "gap-16" : "gap-2"
        } overflow-hidden flex flex-col   h-full`}
      >
        {isActiveTab === 4 &&
          listPizzas?.map((item, index) => (
            <div
              style={
                index === 0
                  ? { animationDuration: "300ms" }
                  : { animationDuration: index * 400 + "ms" }
              }
              className={`bg-gray-500/10 text-center lg:text-left w-full animate-fadeInTop lg:animate-fadeInLeft p-2 flex flex-col lg:flex-row gap-2 items-center cursor-pointer hover:bg-gray-500/20`}
              key={index}
            >
              <img
                className="w-52 h-52 lg:w-44 lg:h-44"
                src={item.image}
                alt=""
              />
              <div className="flex flex-col gap-3">
                <h2
                  className="text-3xl font-semibold"
                  style={{ color: item.nameColor }}
                >
                  {item.name}
                </h2>
                <p className="tracking-wide ">{item.description}</p>
                <b className=" text-red-header  text-xs lg:text-right lg:w-3/4 lg:self-end">
                  {item.size}
                </b>
              </div>
              <span className="border-b border-gray-400 my-4 w-full lg:hidden"></span>
            </div>
          ))}
      </div>
      <div className="w-full m-auto gap-4 my-6 rounded-2xl bg-ExtraMenu bg-auto bg-center flex flex-col pb-6 p-4  items-center">
        <h3 className="uppercase text-2xl text-white font-bold">extras</h3>
        <div className="flex flex-wrap gap-4">
          {extraPizza.map((item, index) => (
            <li key={index} className=" text-white font-bold">
              {item.name}
            </li>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TabMenuPizzas;
