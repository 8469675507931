import PepePkaz1 from "./PepePkaz-1.jpg";
import PepePkaz2 from "./PepePkaz-2.jpg";
import PepePkaz3 from "./PepePkaz-3.jpg";
import PepePkaz4 from "./PepePkaz-4.jpg";
import PepePkaz5 from "./PepePkaz-5.jpg";
import PepePkaz6 from "./PepePkaz-6.jpg";
import PepePkaz7 from "./PepePkaz-7.jpg";
import PepePkaz8 from "./PepePkaz-8.jpg";
import PepePkaz9 from "./PepePkaz-9.jpg";
import PepePkaz10 from "./PepePkaz-10.jpg";
import PepePkaz11 from "./PepePkaz-11.jpg";
import PepePkaz12 from "./PepePkaz-12.jpg";
import PepePkaz13 from "./PepePkaz-13.jpg";
import PepePkaz14 from "./PepePkaz-14.jpg";
import PepePkaz15 from "./PepePkaz-15.jpg";
import PepePkaz16 from "./PepePkaz-16.jpg";
import PepePkaz17 from "./PepePkaz-17.jpg";
import PepePkaz18 from "./PepePkaz-18.jpg";
import PepePkaz19 from "./PepePkaz-19.jpg";
import PepePkaz20 from "./PepePkaz-20.jpg";
import PepePkaz21 from "./PepePkaz-21.jpg";
import PepePkaz22 from "./PepePkaz-22.jpg";
import PepePkaz23 from "./PepePkaz-23.jpg";
import PepePkaz24 from "./PepePkaz-24.jpg";
import PepePkaz25 from "./PepePkaz-25.jpg";

export const galeriaPepkaz = [
  {
    image: PepePkaz1,
  },
  {
    image: PepePkaz2,
  },
  {
    image: PepePkaz3,
  },
  {
    image: PepePkaz4,
  },
  {
    image: PepePkaz5,
  },
  {
    image: PepePkaz6,
  },
  {
    image: PepePkaz7,
  },
  {
    image: PepePkaz8,
  },
  {
    image: PepePkaz9,
  },
  {
    image: PepePkaz10,
  },
  {
    image: PepePkaz11,
  },
  {
    image: PepePkaz12,
  },
  {
    image: PepePkaz13,
  },
  {
    image: PepePkaz14,
  },
  {
    image: PepePkaz15,
  },
  {
    image: PepePkaz16,
  },
  {
    image: PepePkaz17,
  },
  {
    image: PepePkaz18,
  },
  {
    image: PepePkaz19,
  },
  {
    image: PepePkaz20,
  },
  {
    image: PepePkaz21,
  },
  {
    image: PepePkaz22,
  },
  {
    image: PepePkaz23,
  },
  {
    image: PepePkaz24,
  },
  {
    image: PepePkaz25,
  },
];
export const galeriaSlide1 = [
  {
    image: PepePkaz1,
  },
  {
    image: PepePkaz2,
  },
  {
    image: PepePkaz3,
  },
  {
    image: PepePkaz4,
  },
  {
    image: PepePkaz5,
  },
  {
    image: PepePkaz6,
  },
  {
    image: PepePkaz7,
  },
  {
    image: PepePkaz8,
  },
];
export const galeriaSlide2 = [
  {
    image: PepePkaz9,
  },
  {
    image: PepePkaz10,
  },
  {
    image: PepePkaz11,
  },
  {
    image: PepePkaz12,
  },
  {
    image: PepePkaz13,
  },
  {
    image: PepePkaz14,
  },
  {
    image: PepePkaz15,
  },
  {
    image: PepePkaz16,
  },
];
export const galeriaSlide3 = [
  {
    image: PepePkaz17,
  },
  {
    image: PepePkaz18,
  },
  {
    image: PepePkaz19,
  },
  {
    image: PepePkaz20,
  },
  {
    image: PepePkaz21,
  },
  {
    image: PepePkaz22,
  },
  {
    image: PepePkaz23,
  },
  {
    image: PepePkaz24,
  },
  {
    image: PepePkaz25,
  },
];
