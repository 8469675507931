import React from "react";
import { useState, useRef } from "react";
import emailjs from "@emailjs/browser";

const initialForm = {
  mail: "",
  subject: "",
  message: "",
};

const validationsForm = (form) => {
  let errors = {};
  let regexEmail = /^(\w+[/./-/_]?){1,}@[a-z]+[/.]\w{2,}$/;
  let regexMessage = /^.{1,255}$/;

  if (!form.mail.trim()) {
    errors.mail = "*";
  } else if (!regexEmail.test(form.mail.trim())) {
    errors.mail = "El campo 'Email' es incorrecto";
  }

  if (!form.subject.trim()) {
    errors.subject = "*";
  }

  if (!form.message.trim()) {
    errors.message = "*";
  } else if (!regexMessage.test(form.message.trim())) {
    errors.comments =
      "El campo 'Comentarios' no debe exceder los 255 caracteres";
  }
  return errors;
};

const ContactForm = () => {
  const [form, setForm] = useState(initialForm);
  const [errors, setErrors] = useState({});
  const [response, setResponse] = useState(null);
  const [responseError, setResponseError] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setForm({
      ...form,
      [name]: value,
    });
  };
  const handleBlur = (e) => {
    handleChange(e);
    setErrors(validationsForm(form));
  };
  const formRef = useRef();

  const sendEmail = async (e) => {
    e.preventDefault();

    const url = "https://api.emailjs.com/api/v1.0/email/send";
    const controller = new AbortController();
    const signal = controller.signal;

    const fail = () => {
      setResponseError(true);
      setTimeout(() => setResponseError(false), 3500);
    };

    // await fetch(url, { signal: AbortSignal.timeout(5) });
    // try {
    //   await fetch(url, { signal: AbortSignal.timeout(5) });
    // } catch (error) {
    //   if (signal.aborted) {
    //     console.log("error");
    //     fail();
    //   }
    // }

    emailjs
      .sendForm(
        "service_wvh2kct",
        "template_pbqogbr",
        formRef.current,
        "WxIRrOEY9dImu_pmI"
      )
      .then(
        (result) => {
          setResponse(true);
          setForm(initialForm);
          setTimeout(() => setResponse(false), 3500);
        },
        (error) => {
          fail();
        }
      );
  };

  return (
    <form
      ref={formRef}
      className="w-80 bg-[#00cbf6] m-auto p-6 flex flex-col gap-6 rounded-md"
      onSubmit={sendEmail}
    >
      <h3 className="font-bold text-2xl text-white text-center">Escríbenos</h3>
      <div className="flex relative">
        {errors.mail && (
          <p className="absolute top-0.5 right-2 text-[#ff6c99] text-xs">
            {errors.mail}
          </p>
        )}
        <input
          onBlur={handleBlur}
          onChange={handleChange}
          value={form.mail}
          className="px-2 py-3 rounded-md w-full outline-[#00cbf6]"
          type="mail"
          name="mail"
          id="mail"
          placeholder="Email"
          required
        />
      </div>
      <div className="flex relative">
        {errors.subject && (
          <p className="absolute top-0.5 right-2 text-[#ff6c99] text-xs">
            {errors.subject}
          </p>
        )}
        <input
          onBlur={handleBlur}
          onChange={handleChange}
          value={form.subject}
          className="px-2 py-3 rounded-md w-full outline-[#00cbf6]"
          type="text"
          name="subject"
          id="subject"
          placeholder="Asunto"
          required
        />
      </div>
      <div className="flex relative">
        {errors.message && (
          <p className="absolute top-0.5 right-2 text-[#ff6c99] text-xs">
            {errors.message}
          </p>
        )}
        <textarea
          onBlur={handleBlur}
          onChange={handleChange}
          value={form.message}
          className="w-full px-2 py-3 resize-none rounded-md outline-[#00cbf6]"
          name="message"
          id="message"
          cols=""
          rows=""
          placeholder="Mensaje"
          required
        ></textarea>
      </div>
      <div className="flex justify-between">
        <input
          type="submit"
          value="Enviar"
          className="text-white font-bold bg-[#ff6c99] p-2 w-20 rounded-md cursor-pointer"
        />
        <p
          className={`${
            responseError ? "bg-red-600" : "bg-green-600"
          } rounded-md text-white duration-500 p-2 ${
            response ? "opacity-100" : "opacity-0"
          }`}
        >
          {responseError ? "Envío fallido" : "Envío exitoso"}
        </p>
      </div>
    </form>
  );
};

export default ContactForm;
