import React from "react";
import { useEffect } from "react";
import ReactGA from "react-ga";
import { Route, Routes } from "react-router-dom";
import Contacto from "../pages/Contacto";
import Galeria from "../pages/Galeria";
import Paquetes from "../pages/Paquetes";
import SalonDeFiestas from "../pages/SalonDeFiestas";
import { BtnWhatsApp, TopHeader } from ".";

const SalonFiestas = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  return (
    <div className="relative w-full">
      <Routes>
        <Route path="/salon-de-fiestas" element={<SalonDeFiestas />} />
        <Route path="/paquetes" element={<Paquetes />} />
        <Route path="/galeria" element={<Galeria />} />
        <Route path="/contacto" element={<Contacto />} />
      </Routes>
    </div>
  );
};

export default SalonFiestas;
