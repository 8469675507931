import React from "react";
import { NavLink } from "react-router-dom";
import { useStateContext } from "../context/ContextProvider";
import navLogo from "../data/logo_header.png";

const ResponseNavSalon = () => {
  const { isOpen, handleStopPropagations, responseMenu } = useStateContext();

  return (
    <div
      onClick={responseMenu}
      className={`${
        isOpen ? "left-0" : "-left-full"
      } fixed text-white bg-black/50 w-full h-full top-0 duration-500
           z-50 md:hidden `}
    >
      <div
        onClick={handleStopPropagations}
        className={`${
          isOpen ? "left-0" : "-left-full"
        } bg-TopHeaderBg bg-cover duration-700 absolute h-full w-60 flex flex-col p-6 gap-4`}
      >
        <NavLink onClick={responseMenu} to="/salon-de-fiestas">
          <img
            src={navLogo}
            alt="navlogo"
            className="w-40 animate-wiggle hover:animate-rapidWiggle m-auto"
          />
        </NavLink>
        <NavLink
          onClick={responseMenu}
          to="/salon-de-fiestas"
          style={({ isActive }) => ({
            color: isActive ? "#fede01" : "",
          })}
          className="text-lg font-bold hover:text-[#fede01]"
        >
          <span className="uppercase">inicio</span>
        </NavLink>
        <NavLink
          onClick={responseMenu}
          to="/paquetes"
          style={({ isActive }) => ({
            color: isActive ? "#fede01" : "",
          })}
          className="text-lg font-bold hover:text-[#fede01]"
        >
          <span className="uppercase">paquetes</span>
        </NavLink>
        <NavLink
          onClick={responseMenu}
          to="/galeria"
          style={({ isActive }) => ({
            color: isActive ? "#fede01" : "",
          })}
          className="text-lg font-bold hover:text-[#fede01]"
        >
          <span className="uppercase">galería</span>
        </NavLink>
        <NavLink
          onClick={responseMenu}
          to="/restaurante"
          style={({ isActive }) => ({
            color: isActive ? "#fede01" : "",
          })}
          className="text-lg font-bold hover:text-[#fede01]"
        >
          <span className="uppercase">restaurante</span>
        </NavLink>
        <NavLink
          onClick={responseMenu}
          to="/contacto"
          style={({ isActive }) => ({
            color: isActive ? "#fede01" : "",
          })}
          className="text-lg font-bold hover:text-[#fede01]"
        >
          <span className="uppercase">contacto</span>
        </NavLink>
      </div>
    </div>
  );
};

export default ResponseNavSalon;
