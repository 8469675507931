import React from "react";
import { SideMenu, TabContentMenu } from "./index";

const MenuContainer = () => {
  return (
    <div className="flex h-full justify-center w-full pb-28">
      <div className="flex h-full flex-col lg:flex-row gap-2 w-full md:w-4/6">
        <SideMenu />
        <TabContentMenu />
      </div>
    </div>
  );
};

export default MenuContainer;
