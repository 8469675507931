import React from "react";
import { NavLink } from "react-router-dom";
import { useStateContext } from "../context/ContextProvider";
import navLogo from "../data/logo_header.png";

const ResponseNavMenu = () => {
  const {
    isOpen,
    responseMenu,
    scrollToPromo,
    scrollToSobreNosotros,
    setIsOpen,
    handleStopPropagations,
  } = useStateContext();

  return (
    <div
      onClick={() => setIsOpen(!isOpen)}
      className={`${
        isOpen ? "left-0" : "-left-full"
      } fixed text-white bg-black/50 w-full h-full top-0 duration-500
         z-50 lg:hidden `}
    >
      <div
        onClick={handleStopPropagations}
        className={`${
          isOpen ? "left-0" : "-left-full"
        } bg-TopHeaderBgRed bg-cover duration-700 absolute h-full w-60 flex flex-col p-6 gap-4`}
      >
        <NavLink onClick={responseMenu} to="/restaurante">
          <img
            src={navLogo}
            alt="navlogo"
            className="w-40 m-auto animate-wiggle hover:animate-rapidWiggle"
          />
        </NavLink>
        <NavLink
          onClick={responseMenu}
          to="/menu"
          style={({ isActive }) => ({
            color: isActive ? "#fede01" : "",
          })}
          className="text-lg font-bold hover:text-[#fede01]"
        >
          <span className="uppercase">menú</span>
        </NavLink>

        <NavLink
          onClick={scrollToPromo}
          to="/restaurante"
          className="cursor-pointer text-lg font-bold hover:text-[#fede01]"
        >
          <span className="uppercase">promociones</span>
        </NavLink>

        <NavLink
          onClick={scrollToSobreNosotros}
          to="/restaurante"
          className="cursor-pointer text-lg font-bold hover:text-[#fede01]"
        >
          <span className="uppercase">nosotros</span>
        </NavLink>

        <a
          href="http://wa.me/+525541296373"
          target="_blank"
          refs="noopener noreferrer"
          className="cursor-pointer text-lg font-bold hover:text-[#fede01]"
        >
          <span className="uppercase">servicio a Domicilio</span>
        </a>
        <NavLink
          onClick={responseMenu}
          to="/salon-de-fiestas"
          className="text-lg font-bold hover:text-[#fede01]"
        >
          <span className="uppercase">Salón</span>
        </NavLink>
      </div>
    </div>
  );
};

export default ResponseNavMenu;
