import React from "react";
import { NavLink } from "react-router-dom";
import navLogo from "../data/logo_header.png";

const NavBarAviso = () => {
  return (
    <>
      <div className="w-full flex relative h-36 z-10 justify-center">
        <NavLink className="absolute mt-3" to="/">
          <img
            src={navLogo}
            alt="navlogo"
            className="w-36 sm:w-40 animate-wiggle hover:animate-rapidWiggle"
          />
        </NavLink>

        <div className="text-white w-full bg-TopHeaderBg bg-cover flex gap-8 justify-center items-center p-2">
          <NavLink
            to="/salon-de-fiestas"
            style={({ isActive }) => ({
              color: isActive ? "#fede01" : "",
            })}
            className="text-sm sm:text-lg font-bold w-full text-center lg:text-right pr-1/3 hover:text-[#fede01]"
          >
            <span className="uppercase">salón de fiestas</span>
          </NavLink>
        </div>
        <div className="text-white  w-full flex bg-TopHeaderBgRed bg-cover gap-8 justify-center items-center p-2">
          <NavLink
            to="/restaurante"
            className="text-sm sm:text-lg font-bold w-full text-center lg:text-left pl-1/3 hover:text-[#fede01]"
          >
            <span className="uppercase">restaurante</span>
          </NavLink>
        </div>
      </div>
    </>
  );
};

export default NavBarAviso;
