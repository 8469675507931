import React, { useState } from "react";
import { animateScroll as scroll } from "react-scroll";
import TransitEnterexitIcon from "@mui/icons-material/TransitEnterexit";

const ScrollToTop = () => {
  const [show, setShow] = useState(false);

  const showBtn = () => {
    if (window.scrollY >= 300) {
      setShow(true);
    } else setShow(false);
  };

  window.addEventListener("scroll", showBtn);

  const scrollToTop = () => {
    scroll.scrollToTop();
  };
  return (
    <button
      onClick={scrollToTop}
      className={`${
        show ? "opacity-100" : " opacity-0"
      } duration-300 fixed bg-red-header rounded-md w-10 h-10 hover:-translate-y-1 z-50 bottom-8 right-6`}
    >
      <TransitEnterexitIcon className="rotate-135 text-white/80" />
    </button>
  );
};

export default ScrollToTop;
