import React, { useEffect, useRef } from "react";
import { useStateContext } from "../context/ContextProvider";
import Face from "../data/icon_face.png";
import Insta from "../data/icon_insta.png";
import TikT from "../data/icon_tiktok.png";
import Whats from "../data/icon_whtasap.png";

const Siguenos = () => {
  const { setSiguenos } = useStateContext();
  const siguenos = useRef();
  useEffect(() => {
    const onPageLoad = () => {
      const offsetT = siguenos.current.offsetTop;
      setSiguenos(offsetT);
    };

    if (document.readyState === "complete") {
      onPageLoad();
    } else {
      window.addEventListener("load", onPageLoad);
    }
    return () => {
      window.removeEventListener("load", onPageLoad);
    };
  }, []);
  return (
    <div
      ref={siguenos}
      id="siguenos"
      className="bg-bannerOrnamentsSiguenos w-full h-30main md:h-96 py-12 md:py-16 px-6 lg:px-32"
    >
      <div className="grid md:grid-cols-2 justify-center items-start gap-6">
        <div className="flex flex-col gap-2 md:gap-6 items-start relative lg:-top-8">
          <h2 className="text-5xl md:text-7xl lg:text-8xl font-bold text-[#ff1c45]">
            Síguenos
          </h2>
          <b className="text-[#3e56b7] md:text-xl lg:text-2xl">
            en nuestras redes sociales
          </b>
          <div className="flex gap-2">
            <a
              href="https://www.facebook.com/salondefiestasinfantilespepepkaz/"
              target="_blank"
              rel="noopener noreferrer"
              className="w-12 md:w-14 hover:-translate-y-1 cursor-pointer duration-300"
            >
              <img className="" src={Face} alt="" />
            </a>
            <span className="w-12 md:w-14 hover:-translate-y-1 cursor-pointer duration-300">
              <img className="" src={Insta} alt="" />
            </span>
            <span className="w-12 md:w-14 hover:-translate-y-1 cursor-pointer duration-300">
              <img className="" src={TikT} alt="" />
            </span>
            <a
              href="https://wa.me/+525541296373"
              target="_blank"
              rel="noopener noreferrer"
              className="w-12 md:w-14 hover:-translate-y-1 cursor-pointer duration-300"
            >
              <img className="" src={Whats} alt="" />
            </a>
          </div>
        </div>
        <div className="w-full  h-52">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3757.4179346143997!2d-99.10353948577809!3d19.652167238716867!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d1f38a09999329%3A0x84ed9eefab6370ee!2sSalon%20Pepe%20Pkaz!5e0!3m2!1ses!2scu!4v1663483585966!5m2!1ses!2scu"
            width="100%"
            height="100%"
          />
        </div>
      </div>
    </div>
  );
};

export default Siguenos;
