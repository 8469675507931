import React from "react";
import {
  BtnWhatsApp,
  Contact,
  Footer,
  NavBarSalon,
  TopHeader,
} from "../components";
import { useEffect } from "react";
import { useStateContext } from "../context/ContextProvider";

const Contacto = () => {
  const { setBgHeaders } = useStateContext();
  useEffect(() => {
    setBgHeaders(true);
  }, []);
  return (
    <>
      <TopHeader phone={+525529911638} textWhatsApp="55 2991 1638" />
      <BtnWhatsApp phone={+525529911638} />
      <NavBarSalon />
      <div className="bg-BgMenu md:bg-cover pb-20 ">
        <div className="relative -mt-">
          <Contact />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Contacto;
