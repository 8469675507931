import React from "react";
import { ContactForm } from ".";

import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";

import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";

const Contact = () => {
  return (
    <>
      <div className="w-full bg-map bg-cover bg-center h-96 lg:h-30main"></div>
      <div className="relative  -top-12 gap-4 flex flex-col md:flex-row md:justify-center ">
        <div className="">
          <ContactForm />
        </div>

        <div className="bg-white self-center w-80 xl:w-auto p-6 gap-6 xl:gap-14 flex flex-col rounded-md">
          <div className="flex flex-col">
            <b className="text-[#ff6c99]">Pepe Pkaz</b>
            <h2 className="text-[#262d78] text-2xl font-bold">
              Ponte en Contacto
            </h2>
            <h5 className="text-gray-500">
              Síguenos en nuestras redes sociales
            </h5>
          </div>

          <div className="flex gap-3">
            <a
              href="https://www.facebook.com/salondefiestasinfantilespepepkaz/"
              target="_blank"
              rel="noopener noreferrer"
              className="rounded-full bg-[#ff6c99] flex p-1.5 cursor-pointer duration-300 z-10 hover:-translate-y-1"
            >
              <FacebookOutlinedIcon className="text-white" />
            </a>
            <a
              href="https://www.youtube.com/channel/UCTKgBUQJqMj6b8W4sQaKGjg"
              target="_blank"
              rel="noopener noreferrer"
              className="rounded-full bg-[#00cbf6] flex p-1.5 cursor-pointer duration-300 z-10 hover:-translate-y-1"
            >
              <YouTubeIcon className="text-white" />
            </a>
            <span className="rounded-full bg-[#fbd76f] flex p-1.5 cursor-pointer duration-300 z-10 hover:-translate-y-1">
              <InstagramIcon className="text-white" />
            </span>
          </div>
          <div className="flex flex-col gap-6 xl:flex-row">
            <div className="flex xl:flex-col gap-4">
              <CallIcon className="text-[#ff6c99]" />
              <div className="">
                <h4 className="text-[#262d78] text-md font-bold">Teléfono</h4>
                <p className="text-gray-500 text-sm">
                  Tel Oficina: 5558984801
                  <br />
                  Celular: 5529911638
                </p>
              </div>
            </div>
            <div className="flex xl:flex-col gap-4">
              <EmailIcon className="text-[#00cbf6]" />
              <div className="">
                <h4 className="text-[#262d78] text-md font-bold">Email</h4>
                <p className="text-gray-500 text-sm">gerencia@pepepkaz.com</p>
              </div>
            </div>
            <div className="flex xl:flex-col gap-4">
              <LocationOnIcon className="text-[#262d78]" />
              <div className="">
                <h4 className="text-[#262d78] text-md font-bold">Dirección</h4>
                <p className="text-gray-500 text-sm">
                  Calle Triomas Mz 24 Lt 43
                  <br />
                  Los Héroes Coacalco C.P.
                  <br />
                  55710 Edo. de Méx. México
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
