import React from "react";
import { NavLink } from "react-router-dom";
import navLogo from "../data/logo_header.png";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import CloseIcon from "@mui/icons-material/Close";
import { useStateContext } from "../context/ContextProvider";
import ResponseNavMenu from "./ResponseNavMenu";

const NavBar = () => {
  const { isOpen, setIsOpen, scrollToPromo, scrollToSobreNosotros } =
    useStateContext();

  return (
    <>
      <div className="w-full h-36 bg-red-header z-10">
        <div className="text-white hidden lg:flex gap-8 justify-center items-center p-2">
          <NavLink
            to="/menu"
            style={({ isActive }) => ({
              color: isActive ? "#fede01" : "",
            })}
            className="text-lg font-bold hover:text-[#fede01]"
          >
            <span className="uppercase">menú</span>
          </NavLink>

          <NavLink
            onClick={scrollToPromo}
            to="/restaurante"
            className="cursor-pointer text-lg font-bold hover:text-[#fede01]"
          >
            <span className="uppercase">promociones</span>
          </NavLink>

          <NavLink
            onClick={scrollToSobreNosotros}
            to="/restaurante"
            className="cursor-pointer text-lg font-bold hover:text-[#fede01]"
          >
            <span className="uppercase">nosotros</span>
          </NavLink>

          <NavLink to="/restaurante">
            <img
              src={navLogo}
              alt="navlogo"
              className="w-40 animate-wiggle hover:animate-rapidWiggle"
            />
          </NavLink>

          <a
            href="http://wa.me/+525541296373"
            target="_blank"
            refs="noopener noreferrer"
            className="cursor-pointer text-lg font-bold hover:text-[#fede01]"
          >
            <span className="uppercase">servicio a Domicilio</span>
          </a>

          <NavLink
            to="/salon-de-fiestas"
            className="text-lg font-bold hover:text-[#fede01]"
          >
            <span className="uppercase">Salón</span>
          </NavLink>
        </div>
        <div className="justify-between items-center flex lg:hidden px-4 p-2">
          <NavLink to="/restaurante">
            <img
              src={navLogo}
              alt="navlogo"
              className="w-40 animate-wiggle hover:animate-rapidWiggle"
            />
          </NavLink>

          {isOpen ? (
            <CloseIcon
              onClick={() => setIsOpen(!isOpen)}
              className="text-20 mr-3 cursor-pointer text-white hover:rotate-90"
            />
          ) : (
            <MenuOutlinedIcon
              onClick={() => setIsOpen(!isOpen)}
              className="text-20 mr-3 cursor-pointer text-white"
            />
          )}
        </div>
        <ResponseNavMenu />
      </div>
    </>
  );
};

export default NavBar;
