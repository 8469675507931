import ClasicBurger from "./img_hamburclasica.png";
import TropicBurger from "./img_hambutropical.png";
import ChampQuesoBurger from "./img_hamchampiqueso.png";
import AnchsBurger from "./img_hamranchs.png";

//entradas
import PapasFeancesa from "./entradas/prod_papasfrancesa.png";
import Dedos from "./entradas/prod_dedosqueso.png";
import Vegetales from "./entradas/prod_vegetales.png";
import Nuggets from "./entradas/prod_nuggtes.png";
import HotD from "./entradas/prod_hotdogs.png";
import Alitas from "./entradas/prod_alitas.png";
import Boneless from "./entradas/prod_booneles.png";
import Triple from "./entradas/prod_eltriple.png";

//BDogs
import TropiDog from "./bigdogs/prod_champidogs.png";
import Choridog from "./bigdogs/prod_tropidogs.png";
import Champidog from "./bigdogs/prod_choridog.png";

//Pizza
import Sencilla from "./pizza/prod_pizzasencilla.png";
import Tropical from "./pizza/prod_pizzatropical.png";
import Combinada from "./pizza/prod_pizzacombinada.png";
import Campirana from "./pizza/prod_pizzascampirana.png";

//postres
import Suave from "./postres/prod_heladosuave.png";
import Sundae from "./postres/prod_sundai.png";
import Pastel from "./postres/prod_pastel.png";

//BFrias
import Mokachino from "./bebidas-frias/prod_mokachino.png";
import Capuchino from "./bebidas-frias/prod_mcafecapuchino.png";
import Italiana from "./bebidas-frias/prod_sodaitaliana.png";
import Malteada from "./bebidas-frias/prod_malteada.png";
import Camoyada from "./bebidas-frias/prod_chamoyada.png";
import Leche from "./bebidas-frias/prod_frappeleche.png";
import Familia from "./bebidas-frias/prod_coca.png";
import Agua from "./bebidas-frias/prod_agua.png";

//BCalientes
import Americano from "./bebidas-calientes/prod_cafeamericano.png";
import Expreso from "./bebidas-calientes/prod_cafeexpresso.png";
import Te from "./bebidas-calientes/prod_te.png";

export const listEntradas = [
  {
    image: PapasFeancesa,
    name: "Papas a la francesa",
    nameColor: "#ab192e",
    description:
      "Deliciosas papas a la francesa, con un toque crujiente preparadas justo al momento",
    size: "(Orden de papas 250gms)",
  },
  {
    image: Dedos,
    name: "Dedos de queso",
    nameColor: "#49ebc4",
    description:
      "Queso mozzarella cubiertos de exquisita masa a las finas hierbas, acompañados con una porción de papas a la francesa",
    size: "(Acompañados por una porción de papas)",
  },
  {
    image: Vegetales,
    name: "Vegetales",
    nameColor: "#0082fd",
    description: "Excelente combinación de vegetales frescos y de temporada.",
    size: "(Apio, zanahoria, pepinos y jicama)",
  },

  {
    image: Nuggets,
    name: "Nuggets",
    nameColor: "#ab192e",
    description: "Dinosaurios crujientes de pollo preparados al momento.",
    size: "(Acompañados por una porción de papas)",
  },
  {
    image: HotD,
    name: "HoT Dogs",
    nameColor: "#49ebc4",
    description:
      "3 hot dogs preparados a la plancha con el cariño de nuestros cocineros, toque único y especial.",
    size: "(Acompañados  con jitomate, cebolla y jalapeños)",
  },
  {
    image: Alitas,
    name: "Alitas",
    nameColor: "#f9b931",
    description:
      "Suaves por dentro y crujientes por fuera preparadas con nuestras 3 salsas especiales ",
    size: "(Original HOT, BBQ, BBQ Habanera)",
  },
  {
    image: Boneless,
    name: "Boneless",
    nameColor: "#0082fd",
    description:
      "Trocitos de pechuga de pollo cubiertos con nuestra masa especial bañados de nuestras 3 salsas especiales ",
    size: "(Original HOT, BBQ, BBQ Habanera)",
  },

  {
    image: Triple,
    name: "El Triple",
    nameColor: "#ab192e",
    description:
      "La mezcla perfecta para compartir con familiares y amigos ya que contiene Salchipulpos, Nuggets y Alitas bañadas con tu salsa preferida.",
    size: "(Salchipulpos, nuggets y alitas)",
  },
];

export const listBigDogs = [
  {
    image: TropiDog,
    name: "TropiDog",
    nameColor: "#ab192e",
    description:
      "Hot Dog con salchicha jumbo con una cama de trocitos de piña y tocino a la plancha.",
    orden: "Todas nuestros Big Dogs incluyen una orden de papas",
  },

  {
    image: Choridog,
    name: "Choridog",
    nameColor: "#49ebc4",
    description:
      "Hot Dog con salchicha jumbo con una costra de queso Oaxaca y chorizo de la más alta calidad.",
    orden: "Todas nuestros Big Dogs incluyen una orden de papas",
  },

  {
    image: Champidog,
    name: "Champidog",
    nameColor: "#f9b931",
    description:
      "Hot Dog con salchicha jumbo con una costra de queso Oaxaca y champiñones salteados a la plancha.",
    orden: "Todas nuestros Big Dogs incluyen una orden de papas",
  },
];

export const listBurguer = [
  {
    image: ClasicBurger,
    name: "Pepe Pkaz Clásica",
    nameColor: "#ab192e",
    description:
      "(La clásica de la casa está preparada con nuestra deliciosa carne de arrachera en pan de ajonjolí preparada al momento a la plancha con nuestros vegetales frescos)",
    include: "Todas nuestras Hamburguesas incluyen papas y refresco de lata",
  },
  {
    image: TropicBurger,
    name: "Pepe Pkaz Tropical",
    nameColor: "#49ebc4",
    description:
      "(La mezcla perfecta que te transporta al caribe en una mordida, carne de arrachera con una rodaja de piña a la plancha para un toque crujiente y dulce en pan de ajonjolí con nuestros vegetales frescos)",
    include: "Todas nuestras Hamburguesas incluyen papas y refresco de lata",
  },
  {
    image: ChampQuesoBurger,
    name: "Pepe Pkaz Champiqueso",
    nameColor: "#f9b931",
    description:
      "(Nuestros champiñones salteados en la plancha y una cama de queso manchego le dan el toque especial a nuestra carne de arrachera a la plancha con nuestro especial pan brioche)",
    include: "Todas nuestras Hamburguesas incluyen papas y refresco de lata",
  },
  {
    image: AnchsBurger,
    name: "Pepe Pkaz Ranch",
    nameColor: "#0082fd",
    description:
      "(Salchicha, tocino y carne de arrachera en pan brioche, hace que esta hamburguesa sea la preferida para los clientes con mayor apetito)",
    include: "Todas nuestras Hamburguesas incluyen papas y refresco de lata",
  },
];

export const extraBurguer = [
  {
    name: "carne",
  },
  {
    name: "q. oaxaca",
  },
  {
    name: "q. americano",
  },
  {
    name: "tocino",
  },
  {
    name: "q. manchego",
  },
];

export const listPizzas = [
  {
    image: Sencilla,
    name: "Pepe Pizza Sencilla",
    nameColor: "#ab192e",
    description:
      "De jamón o peperonni preparada al horno con nuestra receta de masa especial, salsa de tomate secreta y delicioso queso mozzarella 100%",
    size: "Contamos con tamaño Mediano 35cm y Grande 40cm",
  },
  {
    image: Tropical,
    name: "Pepe Pizza Tropical",
    nameColor: "#49ebc4",
    description:
      "De jamón y trocitos de piña preparada al horno con nuestra receta de masa especial, salsa de tomate secreta y delicioso queso mozzarella 100%",
    size: "Contamos con tamaño Mediano 35cm y Grande 40cm",
  },
  {
    image: Combinada,
    name: "Pepe Pizza Combinada",
    nameColor: "#f9b931",
    description:
      "Jamón, champiñones, cebolla y tocino preparada al horno con nuestra receta de masa especial, salsa de tomate secreta y delicioso queso mozzarella 100%",
    size: "Contamos con tamaño Mediano 35cm y Grande 40cm",
  },
  {
    image: Campirana,
    name: "Pepe Pizza Campirana",
    nameColor: "#0082fd",
    description:
      "Longaniza, tocino, jalapeño, cebolla, cilantro y aguacate preparada al horno con nuestra receta de masa especial, salsa de tomate secreta y delicioso queso mozzarella 100%",
    size: "Contamos con tamaño Mediano 35cm y Grande 40cm",
  },
];
export const extraPizza = [
  {
    name: "Deliciosa orilla rellena de queso crema con chispas de tocino, la única de su estilo",
  },
  {
    name: "Tocino",
  },
  {
    name: "Pepperoni",
  },
  {
    name: "Jamón",
  },
  {
    name: "Tocino",
  },
  {
    name: "Longaniza",
  },
  {
    name: "Piña",
  },
  {
    name: "Champiñones",
  },
  {
    name: "Queso extra",
  },
  {
    name: "Cebolla",
  },
  {
    name: "Aguacate",
  },
];

export const listPostres = [
  {
    image: Suave,
    name: "Helado Suave",
    nameColor: "#ab192e",
    description:
      "Con un sabor único e inigualable helado en cono sabor chocolate, vainilla o combinado.",
    size: "Tamño chico y grande",
  },
  {
    image: Sundae,
    name: "Sundae",
    nameColor: "#49ebc4",
    description:
      "Nuestro helado de vainilla suave servido en vaso acompañado con jarabes de chocolate, fresa o cajeta.",
    size: "Tamño chico y grande",
  },
  {
    image: Pastel,
    name: "Rebanada de Pastel",
    nameColor: "#f9b931",
    description: "Delicioso pastel de triple chocolate.",
    size: "Tamño chico y grande",
  },
];

export const listBebidasFrias = [
  {
    image: Mokachino,
    name: "Café Mokachino",
    nameColor: "#ab192e",
    description:
      "( Café capuchino especial, un toque de chocolate y hielos 12oz)",
  },
  {
    image: Capuchino,
    name: "Café Capuchino",
    nameColor: "#49ebc4",
    description: "(Café capuchino especial 12oz)",
  },
  {
    image: Italiana,
    name: "Soda Italiana",
    nameColor: "#f9b931",
    description:
      "(Agua mineral y soda de limón súper fríos con un toque de arándano, Pink limonade o blueberry 16oz)",
  },
  {
    image: Malteada,
    name: "Malteada",
    nameColor: "#49ebc4",
    description:
      "(Deliciosa mezcla de helado con leche deslactosada sabor chocolate, vainilla o fresa 12oz)",
  },
  {
    image: Camoyada,
    name: "Frappe Chamoyada",
    nameColor: "#ab192e",
    description:
      "(Granizado de mango, fresa o limón preparado con chamoy y chile en polvo, tu compañero ideal para los días con mucho sol 12oz)",
  },
  {
    image: Leche,
    name: "Frappe Leche",
    nameColor: "#49ebc4",
    description: "(Galleta Oreo, Chocolate y especial Pepe Pkaz)",
  },
  {
    image: Familia,
    name: "Refresco Familia",
    nameColor: "#f9b931",
    description: "(CocaCola 355 ml Lata)",
  },
  {
    image: Agua,
    name: "Agua Embotellada",
    nameColor: "#49ebc4",
    description: "(Ciel 600 ml)",
  },
];

export const listBCalientes = [
  {
    image: Mokachino,
    name: "Café Mokachino",
    nameColor: "#ab192e",
    description:
      "(Café capuchino especial, un toque de chocolate y hielos 12oz)",
  },
  {
    image: Capuchino,
    name: "Café Capuchino",
    nameColor: "#49ebc4",
    description: "(Café capuchino especial 12oz)",
  },
  {
    image: Americano,
    name: "Café Americano",
    nameColor: "#f9b931",
    description: "(Café veracruzano recién tostado 12oz)",
  },
  {
    image: Expreso,
    name: "Café Expreso",
    nameColor: "#49ebc4",
    description: "(Café recién tostado)",
  },
  {
    image: Te,
    name: "Té",
    nameColor: "#ab192e",
    description:
      "(Té de su preferencia, sabores a escoger: manzanilla, hierbabuena y Limón 12oz)",
  },
];
