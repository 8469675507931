import React from "react";
import { Footer, NavBarAviso } from "../components";

const AvisoPrivacidad = () => {
  return (
    <>
      <NavBarAviso />
      <div className="p-4 pb-14 md:py-8 md:pb-20 bg-BgMenu">
        <div className="flex flex-col gap-3 md:w-3/4 lg:w-2/3 m-auto">
          <h2 className="self-center text-xl font-bold">AVISO DE PRIVACIDAD</h2>
          Estos serán utilizados única y exclusivamente para los siguientes
          fines: 1. Información y Prestación de Servicios. 2. Actualización de
          la Base de Datos. 3. Cualquier finalidad análoga o compatible con las
          anteriores. En el caso de Datos sensibles, tales como: 1. Datos
          Financieros (Ingresos, Estados de Cuenta, y demás relacionados) 2.
          Datos Patrimoniales (Bienes Materiales, Inmuebles, y demás
          relacionados) 3. Datos Personales (Cónyuge, Estado Civil,
          Nacionalidad, Educación, Hijos, y demás relacionados). 4. Referencias
          familiares y no familiares (Nombre, Dirección, Teléfono, relación,
          etc.). Estos serán utilizados única y exclusivamente para los
          siguientes fines: 1. Investigación y/u Obtención de Créditos ante las
          Instituciones Financieras. 2. Cualquier finalidad análoga o compatible
          con la anterior. 3. Información y Prestación de Servicios Para
          prevenir el acceso no autorizado a sus datos personales y con el fin
          de asegurar que la información sea utilizada para los fines
          establecidos en este aviso de privacidad, hemos establecido diversos
          procedimientos con la finalidad de evitar el uso o divulgación no
          autorizados de sus datos, permitiéndonos tratarlos debidamente. Así
          mismo, le informamos que sus datos personales pueden ser Transmitidos
          para ser tratados por personas distintas a esta empresa. Todos sus
          datos personales son tratados de acuerdo a la legislación aplicable y
          vigente en el país, por ello le informamos que usted tiene en todo
          momento los derechos (PEPE PKAZ) de acceder, rectificar, cancelar u
          oponerse al tratamiento que le damos a sus datos personales; derecho
          que podrá hacer valer a través del Área de Privacidad encargada de la
          seguridad de datos personales en el Teléfono 58984801, o por medio de
          su correo electrónico: gerencia@pepepkaz.com A través de estos canales
          usted podrá actualizar sus datos y especificar el medio por el cual
          desea recibir información, ya que en caso de no contar con esta
          especificación de su parte, PEPE PKAZ establecerá libremente el canal
          que considere pertinente para enviarle información. Este aviso de
          privacidad podrá ser modificado por PEPE PKAZ dichas modificaciones
          serán oportunamente informadas a través de correo electrónico,
          teléfono, o cualquier otro medio de comunicación que PEPE PKAZ
          determine para tal efecto. ATENTAMENTE ADMINISTRACION PEPE PKAZ Ultima
          Actualización: 1 Octubre 2022
          <p>
            De acuerdo a lo Previsto en la “Ley Federal de Protección de Datos
            Personales”, declara PEPE PKAZ ser una empresa legalmente
            constituida de conformidad con las leyes mexicanas, con domicilio en
            Triomas Lt43, Héroes Coacalco, Estado de México, 55712.; y como
            responsable del tratamiento de sus datos personales, hace de su
            conocimiento que la información de nuestros clientes es tratada de
            forma estrictamente confidencial por lo que al proporcionar sus
            datos personales, tales como:
          </p>
          <ul className="font-bold">
            <li>1. PEPE PKAZ </li>
            <li>2. Triomas Lt 43, Héroes Coacalco, Estado de México, 55712</li>
            <li>3. X0XXAA00 </li>
            <li>4. 58984801 y 5529911638</li>
            <li>5. gerencia@pepepkaz.com</li>
          </ul>
          <p>
            Estos serán utilizados única y exclusivamente para los siguientes
            fines:
          </p>
          <ul>
            <li>1. Información y Prestación de Servicios.</li>
            <li>2. Actualización de la Base de Datos.</li>
            <li>
              3. Cualquier finalidad análoga o compatible con las anteriores.
            </li>
          </ul>
          <p>En el caso de Datos sensibles, tales como:</p>
          <ul className="font-bold">
            <li>
              1. Datos Financieros (Ingresos, Estados de Cuenta, y demás
              relacionados)
            </li>
            <li>
              2. Datos Patrimoniales (Bienes Materiales, Inmuebles, y demás
              relacionados)
            </li>

            <li>
              3. Datos Personales (Cónyuge, Estado Civil, Nacionalidad,
              Educación, Hijos, y demás relacionados).
            </li>
            <li>
              4. Referencias familiares y no familiares (Nombre, Dirección,
              Teléfono, relación, etc.).
            </li>
          </ul>
          <p>
            Estos serán utilizados única y exclusivamente para los siguientes
            fines:
          </p>
          <ul className="font-bold">
            <li>
              1. Investigación y/u Obtención de Créditos ante las Instituciones
              Financieras.
            </li>
            <li>
              2. Cualquier finalidad análoga o compatible con la anterior.
            </li>
            <li>3. Información y Prestación de Servicios</li>
          </ul>
          <p>
            Para prevenir el acceso no autorizado a sus datos personales y con
            el fin de asegurar que la información sea utilizada para los fines
            establecidos en este aviso de privacidad, hemos establecido diversos
            procedimientos con la finalidad de evitar el uso o divulgación no
            autorizados de sus datos, permitiéndonos tratarlos debidamente.
          </p>
          <p>
            Así mismo, le informamos que sus datos personales pueden ser
            Transmitidos para ser tratados por personas distintas a esta
            empresa.
          </p>
          <p>
            Todos sus datos personales son tratados de acuerdo a la legislación
            aplicable y vigente en el país, por ello le informamos que usted
            tiene en todo momento los derechos (PEPE PKAZ) de acceder,
            rectificar, cancelar u oponerse al tratamiento que le damos a sus
            datos personales; derecho que podrá hacer valer a través del Área de
            Privacidad encargada de la seguridad de datos personales en el
            Teléfono 58984801, o por medio de su correo electrónico:
            gerencia@pepepkaz.com
          </p>
          <p>
            A través de estos canales usted podrá actualizar sus datos y
            especificar el medio por el cual desea recibir información, ya que
            en caso de no contar con esta especificación de su parte, PEPE PKAZ
            establecerá libremente el canal que considere pertinente para
            enviarle información.
          </p>
          <p>
            Este aviso de privacidad podrá ser modificado por PEPE PKAZ dichas
            modificaciones serán oportunamente informadas a través de correo
            electrónico, teléfono, o cualquier otro medio de comunicación que
            PEPE PKAZ determine para tal efecto.
          </p>
          <div className="text-md font-bold md:text-center">
            <h3>ATENTAMENTE</h3>
            <h3>ADMINISTRACIÓN PEPE PKAZ</h3>
          </div>
          <b className="md:text-right">Última Actualización: 1 Octubre 2022</b>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AvisoPrivacidad;
