import React from "react";
import { useEffect } from "react";
import {
  NavBarSalon,
  TopHeader,
  Footer,
  Contact,
  BtnWhatsApp,
} from "../components";
import img from "../data/fondo_rojoslide.png";
import imgSlide from "../data/img_sliderojo.png";
import imgSlideB from "../data/img_azulpekas.png";
import pelotasB from "../data/pelotas_azul.png";
import textRed from "../data/tex_sliderojo.png";
import textYellow from "../data/tex_azulespacios.png";
import textConoce from "../data/tex_venyconcoe.png";
import textMD from "../data/magia-diversion/tex_todaladiversion.png";

import juegos from "../data/magia-diversion/icon_juegos.png";
import magia from "../data/magia-diversion/icon_magia.png";
import maquillista from "../data/magia-diversion/icon_maquillistas.png";
import menu from "../data/magia-diversion/icon_menu.png";
import modulares from "../data/magia-diversion/icon_modulares.png";
import toboganes from "../data/magia-diversion/icon_toboganes.png";
import { useStateContext } from "../context/ContextProvider";
import { NavLink } from "react-router-dom";

const SalonDeFiestas = () => {
  const { setBgHeaders, scrollTop } = useStateContext();
  useEffect(() => {
    document.title = "Salón de Fiestas";
    setBgHeaders(false);
  }, []);
  return (
    <div>
      <TopHeader phone={+525529911638} textWhatsApp="55 2991 1638" />
      <NavBarSalon />
      <BtnWhatsApp phone={+525529911638} />
      {/* ---1--- */}
      <div className="bg-[#0043a4] h-full lg:h:40main relative flex flex-col items-center">
        <img
          className="h-96 sm:h-34main lg:h-full w-full absolute"
          src={img}
          alt=""
        />
        <div className="pt-48 relative flex max-w-md gap-8 flex-col lg:grid lg:grid-cols-2 lg:max-w-none lg:w-8/12 items-center">
          <div className=" h-56 sm:h-72 md:h-96 w-full">
            <img
              className="h-full m-auto animate-price hover:pause"
              src={imgSlide}
              alt=""
            />
          </div>
          <div className="flex items-center text-center text-white flex-wrap justify-center uppercase px-2">
            <img src={textRed} alt="" />
            <p className="normal-case text-2xl">
              Porque sabemos que los niños merecen ser festejados de una manera
              extraordinaria y divertida
            </p>
            <NavLink
              onClick={scrollTop}
              className="mt-8 group relative px-4 duration-300 py-2 text-[#ffbe03] hover:text-[#007be3] font-bold  rounded-md  bg-[#007be3] overflow-hidden"
              to="/paquetes"
            >
              <p className="z-10 relative">Reservar</p>
              <span className="w-44 bg-[#ffbe03] h-44 absolute top-0 rounded-full duration-300 -left-full -translate-x-1/2 group-hover:translate-x-1/3 group-hover:-translate-y-1/2"></span>
            </NavLink>
          </div>
        </div>
      </div>
      {/* ----2---- */}
      <div
        className="bg-[#0043a4] pt-20 pb-6 text-white text-center justify-center items-center px-2 h-full relative z-10 flex flex-col
         
      before:rotate-180 before:h-28 sm:before:h-36 md:before:h-48 lg:before:h-60 xl:before:h-72 before:w-full before:bg-wave before:absolute before:bg-no-repeat before:bg-cover before:bottom-1  before:translate-y-full"
      >
        <img
          className="absolute -left-20 lg:left-0 bottom-0 h-96 animate-moveBot hover:pause"
          src={pelotasB}
          alt=""
        />
        <div className="relative flex flex-col max-w-md gap-8 lg:grid lg:grid-cols-2 lg:max-w-none lg:w-8/12 items-center">
          <div className=" h-56 sm:h-72 md:h-96 w-full relative lg:order-last">
            <img
              className="h-full m-auto animate-price hover:pause"
              src={imgSlideB}
              alt=""
            />
          </div>
          <div className="flex flex-col gap-4 relative">
            <img className="mt-6" src={textYellow} alt="" />
            <p>
              En Pepe PKAZ promovemos el entretenimiento con valores y la unión
              familiar.
            </p>
            <p>
              Nuestros espacios fortalecen el desarrollo y la imaginación del
              niño, hacemos de su fecha especial , un{" "}
              <b className="text-[#fede01]">¡Día inolvidable!</b>
            </p>
            <p>
              Contamos con estructuras donde tú y tus invitados puedan disfrutar
              e interactuar al mismo tiempo desde los 0 a los 99 años
            </p>
            <img className="" src={textConoce} alt="" />
          </div>
        </div>
      </div>
      {/* --3--- */}
      <div
        className="bg-FiestaYellowBg w-full bg-no-repeat relative bg-cover pt-36 lg:pt-64
      before:rotate-180 before:h-28 sm:before:h-36 md:before:h-48 lg:before:h-60 xl:before:h-72 before:w-full before:bg-waveYellow before:absolute before:bg-no-repeat before:bg-cover before:bottom-0 before:translate-y-full
      "
      >
        <img className="lg:w-8/12 m-auto" src={textMD} alt="" />
        <div className="grid gap-12 lg:w-8/12 m-auto lg:grid-cols-3">
          <div className="flex justify-center items-center flex-col hover:animate-wiggle cursor-pointer">
            <img src={juegos} alt="" className="w-72 lg:w-64" />
            <p className="text-2xl text-[#0b4193] font-bold">Juegos</p>
          </div>
          <div className="flex justify-center items-center flex-col hover:animate-wiggle cursor-pointer">
            <img src={modulares} alt="" className="w-72 lg:w-64" />
            <p className="text-2xl text-[#0b4193] font-bold">
              Juegos Modulares
            </p>
          </div>
          <div className="flex justify-center items-center flex-col hover:animate-wiggle cursor-pointer">
            <img src={toboganes} alt="" className="w-72 lg:w-64" />
            <p className="text-2xl text-[#0b4193] font-bold">
              Toboganes y Tiroleza
            </p>
          </div>
          <div className="flex justify-center items-center flex-col hover:animate-wiggle cursor-pointer">
            <img src={maquillista} alt="" className="w-56 lg:w-48" />
            <p className="text-2xl text-[#0b4193] font-bold">Maquillistas</p>
          </div>
          <div className="flex justify-center items-center flex-col hover:animate-wiggle cursor-pointer">
            <img src={magia} alt="" className="w-56 lg:w-44" />
            <p className="text-2xl text-[#0b4193] font-bold">Shows de magia</p>
          </div>
          <div className="flex justify-center items-center flex-col hover:animate-wiggle cursor-pointer">
            <img src={menu} alt="" className="w-56 lg:w-44" />
            <p className="text-2xl text-[#0b4193] font-bold">Menu Kids</p>
          </div>
        </div>
      </div>
      {/* ----4---- */}
      <div className="pt-2 lg:pt-6 pb-6 xl:pb-20 bg-white">
        <Contact />
      </div>
      <Footer />
    </div>
  );
};

export default SalonDeFiestas;
