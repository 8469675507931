import React from "react";
import { useEffect } from "react";
import { NavLink } from "react-router-dom";
import logo from "../data/logo_pkaz.png";

const Home = () => {
  useEffect(() => {
    document.title = "Salon de Eventos Infantiles y Restaurante en Coacalco";
  }, []);
  return (
    <div className="bg-Home bg-cover h-screen w-full flex items-center justify-center flex-col gap-8 sm:gap-12">
      <div className="flex justify-center">
        <img
          className="w-9/12 sm:w-7/12 lg:w-5/12 animate-wiggleSlow hover:pause"
          src={logo}
          alt=""
        />
      </div>
      <div className=" flex justify-around w-full sm:w-8/12 lg:w-6/12">
        <NavLink
          className="group relative p-2 text-white hover:border-[#0088c2] hover:text-[#0088c2] font-bold border-2 rounded-md border-white bg-[#0088c2] overflow-hidden"
          to="/salon-de-fiestas"
        >
          <p className="z-10 relative">Salón de Fiestas</p>
          <span className="w-52 bg-white h-52 absolute top-0 group-hover:text-[#0088c2]  rounded-full duration-300 -left-full -translate-x-1/3 group-hover:translate-x-1/2 group-hover:-translate-y-1/2"></span>
        </NavLink>
        <NavLink
          className="group relative p-2 text-white hover:border-[#ff1643] hover:text-[#ff1643] font-bold border-2 rounded-md border-white bg-[#ff1643] overflow-hidden"
          to="/restaurante"
        >
          <p className="z-10 relative">Restaurante</p>
          <span className="w-40 bg-white h-40 absolute top-0  group-hover:text-[#ff1643]  rounded-full duration-300 -left-full -translate-x-1/3 group-hover:translate-x-1/2 group-hover:-translate-y-1/2"></span>
        </NavLink>
      </div>
    </div>
  );
};

export default Home;
