import React from "react";
import { useEffect } from "react";
import ReactGA from "react-ga";
import { Routes, Route } from "react-router-dom";
import Index from "../pages/Index";
import Menu from "../pages/Menu";
import { BtnWhatsApp, TopHeader } from ".";

const Restaurante = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  return (
    <>
      <Routes>
        <Route path="/restaurante" element={<Index />} />
        <Route path="/menu" element={<Menu />} />
      </Routes>
    </>
  );
};

export default Restaurante;
