import React from "react";
import { useEffect } from "react";
import {
  BtnWhatsApp,
  Footer,
  MenuBanner,
  MenuContainer,
  NavBarMenu,
  TopHeader,
  TopHeaderMenu,
} from "../components";

const Menu = () => {
  useEffect(() => {
    document.title = "Restaurante de Pepepkaz";
  }, []);
  return (
    <div className="w-full">
      <TopHeader phone={+525541296373} textWhatsApp="55 4129 6373" />
      <NavBarMenu />
      <div className="bg-BgMenu px-2 pb-4 md:px-0 lg:pb-24">
        <MenuBanner />
        <MenuContainer />
      </div>
      <Footer />
      <BtnWhatsApp phone={+525541296373} />
    </div>
  );
};

export default Menu;
