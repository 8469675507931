import React from "react";
import {
  Footer,
  NavBarSalon,
  TopHeader,
  GaleriaS,
  ButtonBuble,
  BtnWhatsApp,
} from "../components";
import { useEffect } from "react";
import { useStateContext } from "../context/ContextProvider";

import pkas_momentosgaleria from "../data/galeria/pkas_momentosgaleria.png";
import tex_tegustaria from "../data/galeria/tex_tegustaria.png";
import { NavLink } from "react-router-dom";

const Galeria = () => {
  const { setBgHeaders, scrollTop } = useStateContext();
  useEffect(() => {
    setBgHeaders(true);
  }, []);

  return (
    <>
      <TopHeader phone={+525529911638} textWhatsApp="55 2991 1638" />
      <BtnWhatsApp phone={+525529911638} />
      <NavBarSalon />
      <div className="pt-48 bg-BgMenu md:bg-cover gap-14 lg:gap-20 pb-20 lg:pb-32">
        <div className="px-3 flex flex-col gap-6">
          <img className="lg:w-3/6 m-auto" src={pkas_momentosgaleria} alt="" />

          <GaleriaS />

          <div className="flex flex-col items-center gap-6 lg:w-3/6 m-auto">
            <img src={tex_tegustaria} alt="" />
            <NavLink to="/contacto" onClick={scrollTop}>
              <ButtonBuble
                btnColor="#ff1643"
                textC="#f6d806"
                bubleColor="#0058ba"
                text="¡Cotizar ahora!"
                rounded="1rem"
                hoverColor="white"
              />
            </NavLink>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Galeria;
