import React, { createContext, useContext, useState } from "react";
import { animateScroll as scroll } from "react-scroll";

const StateContext = createContext();

export const ContextProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isActiveTab, setisActiveTab] = useState(1);
  const [promociones, setPromociones] = useState(0);
  const [sobreNosotros, setSobreNosotros] = useState(0);
  const [siguenos, setSiguenos] = useState(0);
  const [toScrollSideMenu, setToScrollSideMenu] = useState(0);
  const [bgHeaders, setBgHeaders] = useState(false);

  const [mobileActiveM, setmobileActiveM] = useState(false);
  const [mobileActiveMAuto, setmobileActiveMAuto] = useState(true);

  const [screenSize, setScreenSize] = useState(undefined);

  const handleStopPropagations = (e) => e.stopPropagation();

  const scrollTop = () => {
    scroll.scrollTo(0);
  };

  const handleActive = (tabNumbre) => {
    if (isActiveTab !== tabNumbre) {
      setisActiveTab(tabNumbre);
    }
    scroll.scrollTo(340);

    if (screenSize <= 1024) {
      setmobileActiveM(false);
      setmobileActiveMAuto(false);
    }
  };
  const handleClickTop = (num) => {
    setisActiveTab(num);
    scroll.scrollTo(toScrollSideMenu);
  };
  const responseMenu = () => {
    setIsOpen(false);
  };
  const scrollToPromo = () => {
    scroll.scrollTo(promociones);
    setIsOpen(false);
  };
  const scrollToSobreNosotros = () => {
    scroll.scrollTo(sobreNosotros);
    setIsOpen(false);
  };
  const scrollToSiguenos = () => {
    scroll.scrollTo(siguenos);
    setIsOpen(false);
  };

  const data = {
    handleStopPropagations,
    scrollTop,
    bgHeaders,
    setBgHeaders,
    toScrollSideMenu,
    setToScrollSideMenu,
    screenSize,
    setScreenSize,
    mobileActiveM,
    setmobileActiveM,
    mobileActiveMAuto,
    setmobileActiveMAuto,
    handleClickTop,
    responseMenu,
    scrollToPromo,
    scrollToSobreNosotros,
    scrollToSiguenos,
    siguenos,
    setSiguenos,
    sobreNosotros,
    setSobreNosotros,
    setPromociones,
    promociones,
    isOpen,
    setIsOpen,
    handleActive,
    isActiveTab,
    setisActiveTab,
  };

  return <StateContext.Provider value={data}>{children}</StateContext.Provider>;
};

export const useStateContext = () => useContext(StateContext);
