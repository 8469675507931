import React from "react";
import {
  galeriaSlide1,
  galeriaSlide2,
  galeriaSlide3,
} from "../data/galeria/images";
import icon_ver from "../data/galeria/icon_ver.png";

import { useState } from "react";

import Slider from "react-slick";
import { ButtonBuble } from "./index";
import { useStateContext } from "../context/ContextProvider";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        zIndex: "10",
        display: "flex",
        right: "0",
        justifyContent: "center",
        alignItems: "center",
        background: "#0056b370",
        padding: "1rem",
        height: "3rem",
      }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        zIndex: "10",
        display: "flex",
        left: "0",
        justifyContent: "center",
        alignItems: "center",
        background: "#0056b370",
        padding: "1rem",
        height: "3rem",
      }}
      onClick={onClick}
    />
  );
}

const GaleriaS = () => {
  const { handleStopPropagations } = useStateContext();
  let settingsSlide1 = {
    dots: false,
    speed: 2000,
    slidesToShow: 5,
    slidesToScroll: 4,
    initialSlide: 0,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3500,
    swipeToSlide: true,
    pauseOnHover: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1288,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  let settingsSlide2 = {
    dots: false,
    speed: 2500,
    slidesToShow: 5,
    slidesToScroll: 4,
    initialSlide: 0,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 4000,
    swipeToSlide: true,
    pauseOnHover: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1288,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  let settingsSlide3 = {
    dots: false,
    speed: 3000,
    slidesToShow: 5,
    slidesToScroll: 4,
    initialSlide: 0,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 4500,
    swipeToSlide: true,
    pauseOnHover: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1288,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const [show, setShow] = useState(null);
  const [modal, setModal] = useState(false);

  const handleModal = (src) => {
    setShow(src);
    setModal(true);
  };
  return (
    <div className="">
      <div className="bg-[#0056b3] rounded-l-lg h-5 w-10/12 relative left-1/5"></div>
      <div className="px-6">
        <Slider {...settingsSlide1}>
          {galeriaSlide1.map((item, index) => (
            <div
              key={index}
              className=" bg-white relative cursor-pointer hover:scale-105 ease-in-out duration-300"
            >
              <img
                onClick={() => handleModal(item.image)}
                className="absolute m-auto w-20 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
                src={icon_ver}
                alt=""
              />
              <img
                onClick={() => handleModal(item.image)}
                className="object-cover w-96 sm:w-64 h-96 sm:h-64 p-2"
                src={item.image}
                alt=""
              />
            </div>
          ))}
        </Slider>
      </div>
      <div className="bg-[#ff1643] rounded-r-lg h-5 w-10/12 relative right-1/5"></div>
      <div className="px-6">
        <Slider {...settingsSlide2}>
          {galeriaSlide2.map((item, index) => (
            <div
              key={index}
              className=" bg-white relative cursor-pointer hover:scale-105 ease-in-out duration-300"
            >
              <img
                onClick={() => handleModal(item.image)}
                className="absolute m-auto w-20 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
                src={icon_ver}
                alt=""
              />
              <img
                onClick={() => handleModal(item.image)}
                className="object-cover w-96 sm:w-64 h-96 sm:h-64 p-2"
                src={item.image}
                alt=""
              />
            </div>
          ))}
        </Slider>
      </div>
      <div className="bg-[#fedb04] rounded-l-lg h-5 w-10/12 relative left-1/5"></div>
      <div className="px-6">
        <Slider {...settingsSlide3}>
          {galeriaSlide3.map((item, index) => (
            <div
              key={index}
              className=" bg-white relative cursor-pointer hover:scale-105 ease-in-out duration-300"
            >
              <img
                onClick={() => handleModal(item.image)}
                className="absolute m-auto w-20 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
                src={icon_ver}
                alt=""
              />
              <img
                onClick={() => handleModal(item.image)}
                className="object-cover w-96 sm:w-64 h-96 sm:h-64 p-2"
                src={item.image}
                alt=""
              />
            </div>
          ))}
        </Slider>
      </div>
      {modal && (
        <div
          onClick={() => setModal(false)}
          className="fixed flex items-center justify-center top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2
        w-full h-full z-50 overflow-hidden bg-black/95"
        >
          <div
            onClick={handleStopPropagations}
            className="relative flex flex-col gap-4 justify-center items-center"
          >
            <img className="lg:w-96" src={show} alt="" />
          </div>
        </div>
      )}
    </div>
  );
};

export default GaleriaS;
