import React from "react";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import MailOutlinedIcon from "@mui/icons-material/MailOutlined";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { useStateContext } from "../context/ContextProvider";
import { useEffect } from "react";

const TopHeader = ({ textWhatsApp, phone }) => {
  const { bgHeaders, screenSize, setScreenSize } = useStateContext();

  useEffect(() => {
    const handleRezise = () => setScreenSize(window.innerWidth);
    window.addEventListener("resize", handleRezise);

    handleRezise();
    return () => window.removeEventListener("resize", handleRezise);
  }, [screenSize]);

  return (
    <div
      className={`${
        bgHeaders ? "bg-TopHeaderBgRed" : "bg-TopHeaderBg"
      } flex z-50 relative  bg-cover px-4 py-3 text-white justify-center`}
    >
      <div className="w-full md:w-10/12 lg:w-8/12 xl:w-7/12 flex justify-between flex-wrap">
        <a
          href="#!"
          target="_blank"
          rel="noopener noreferrer"
          className={`gap-2 ${screenSize < 768 ? "hidden" : "flex"}`}
        >
          <LocationOnIcon className="text-[#fede01]" />
          <p>Nuestra Ubicación</p>
        </a>
        <div
          className={`flex gap-x-4 flex-wrap ${screenSize < 768 && "m-auto"}`}
        >
          <a
            className="flex gap-2"
            href="mailto:gerencia@pepepkaz.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <p>gerencia@pepepkaz.com</p>
            <MailOutlinedIcon className="text-[#fede01]" />
          </a>
          <a
            className="flex gap-2"
            href={`http://wa.me/${phone}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <p>{textWhatsApp}</p>
            <WhatsAppIcon className="text-[#fede01]" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default TopHeader;
