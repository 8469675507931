import React from "react";
import Nubes from "../data/footer-img-top-bg-1.png";
import FooterTop from "../data/footer-top-bg.png";
import FooterBottom from "../data/footer-bottom-bg.png";

import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";

import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import { NavLink } from "react-router-dom";
import { useStateContext } from "../context/ContextProvider";

const Footer = () => {
  const { scrollTop } = useStateContext();
  return (
    <div className="relative bg-[#4559b3]  w-full h-96 md:h-64 before:bg-footerTop before:w-full before:h-20 py-4 z-20">
      <img
        className="absolute w-full -top-3 h-4 md:h-8 md:-top-7 lg:h-11 lg:-top-10 xl:h-16 xl:-top-14"
        src={FooterTop}
        alt=""
      />
      <img className="absolute" src={Nubes} alt="" />
      <div className="flex justify-center w-full h-full">
        <div className="w-full md:w-3/5 flex flex-col md:flex-row justify-center items-center h-full gap-10 lg:gap-20 z-10">
          <div className="flex justify-center gap-3 md:flex-col">
            <div className="flex gap-3">
              <a
                href="https://www.facebook.com/salondefiestasinfantilespepepkaz/"
                target="_blank"
                rel="noopener noreferrer"
                className="rounded-full bg-white flex p-1.5 cursor-pointer duration-300 z-10 hover:-translate-y-1"
              >
                <FacebookOutlinedIcon className="text-[#3e56b7]" />
              </a>
              <a
                href="https://www.youtube.com/channel/UCTKgBUQJqMj6b8W4sQaKGjg"
                target="_blank"
                rel="noopener noreferrer"
                className="rounded-full bg-white flex p-1.5 cursor-pointer duration-300 z-10 hover:-translate-y-1"
              >
                <YouTubeIcon className="text-[#3e56b7]" />
              </a>
            </div>
            <div className="flex">
              <span className="rounded-full bg-white flex p-1.5 cursor-pointer duration-300 z-10 hover:-translate-y-1">
                <InstagramIcon className="text-[#3e56b7]" />
              </span>
            </div>
          </div>

          <div className="flex gap-3 flex-col px-14 lg:px-6">
            <div className=" grid lg:grid-cols-2 gap-3 sm:gap-1 lg:gap-4">
              <a href="tel:5527130360" className="flex gap-4 items-center">
                <span className="rounded-full bg-[#ff6c96] flex p-1.5 w-9 h-9 justify-center items-center cursor-pointer duration-300 z-10">
                  <LocalPhoneIcon className="text-white" />
                </span>
                <span className="flex gap-1 flex-wrap text-white">
                  <h4>Teléfono:</h4>
                  <p>55 2991 1638</p>
                </span>
              </a>
              <a
                href="mailto:gerencia@pepepkaz.com"
                className="flex gap-4 items-center"
              >
                <span className="rounded-full bg-[#00cbf6] flex p-1.5 w-9 h-9 justify-center items-center cursor-pointer duration-300 z-10">
                  <EmailIcon className="text-white" />
                </span>
                <span className="flex flex-col text-white">
                  <h4>Email:</h4>
                  <b>gerencia@pepepkaz.com</b>
                </span>
              </a>
            </div>
            <div className="grid lg:grid-cols-2 gap-3 sm:gap-1 lg:gap-4">
              <span className="flex gap-4 items-center">
                <span className="rounded-full bg-[#ffd376] flex p-1.5 w-9 h-9 justify-center items-center cursor-pointer duration-300 z-10">
                  <LocationOnIcon className="text-white" />
                </span>
                <span className="flex flex-col text-white text-sm">
                  <h4>Dirección:</h4>
                  <b className="">
                    Calle Triomas Mz 24 Lt 43. Los Héroes Coacalco. C.P. 55710
                    Edo. de Méx. México
                  </b>
                </span>
              </span>
              <NavLink
                onClick={scrollTop}
                to="/aviso-de-privacidad"
                className="flex gap-4 items-center shrink-0"
              >
                <span className="rounded-full bg-[#5c029170] flex p-1.5 w-9 h-9 justify-center relative items-center cursor-pointer duration-300">
                  <ContentPasteIcon className="text-white z-10" />
                </span>
                <span className="flex gap-1 text-white">
                  <h4>Aviso de Privacidad</h4>
                </span>
              </NavLink>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-[#3e55ac] relative h-14 ">
        <div className="w-full md:w-3/5 flex justify-center md:justify-start mx-auto relative top-4">
          <p className="text-white z-10 text-xs sm:text-base pt-3 sm:pt-0">
            © Pepepkaz | México 2022 | Diseño web por{" "}
            <a
              href="https://www.dosbytes.com.mx/"
              target="_blank"
              className="font-semibold text-[#fede01]"
            >
              dosbyte.com.mx
            </a>
          </p>
        </div>
        <img
          className="absolute -top-3 md:-top-7 lg:-top-9 xl:-top-12"
          src={FooterBottom}
          alt=""
        />
      </div>
    </div>
  );
};
export default Footer;
