import React from "react";
import {
  TabMenuBigDogs,
  TabMenuBurguer,
  TabMenuEntradas,
  TabMenuPizzas,
  TabMenuPostres,
  TabMenuBebidasF,
  TabMenuBebidasC,
} from ".";

const TabContentMenu = () => {
  return (
    <>
      <TabMenuEntradas />
      <TabMenuBigDogs />
      <TabMenuBurguer />
      <TabMenuPizzas />
      <TabMenuPostres />
      <TabMenuBebidasF />
      <TabMenuBebidasC />
    </>
  );
};

export default TabContentMenu;
