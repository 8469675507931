import React, { useEffect, useState, useRef } from "react";
import TopMenu from "../data/menuico.png";
import Entradas from "../data/icon_entradas.png";
import EntradasWhite from "../data/icon_entradasblanco.png";
import BigDog from "../data/icon_bigdogs.png";
import BigDogWhite from "../data/icon_bigdogsblanco.png";
import Burger from "../data/icon_hamburguesas.png";
import BurgerWhite from "../data/icon_hamburguesablanco.png";
import Pizza from "../data/icon_pizzas.png";
import PizzaWhite from "../data/icon_pizzasblanco.png";
import Postres from "../data/icon_postres.png";
import PostresWhite from "../data/icon_postresblancos.png";
import Bebidas from "../data/icon_bebidasfrias.png";
import BebidasWhite from "../data/icon_bebidasfriasblanco.png";
import Cofe from "../data/icon_bebidascaliente.png";
import CofeWhite from "../data/icon_bebidascalientebalnco.png";
import { useStateContext } from "../context/ContextProvider";

const SideMenu = () => {
  const {
    handleActive,
    isActiveTab,
    mobileActiveM,
    setmobileActiveM,
    mobileActiveMAuto,
    setmobileActiveMAuto,
    setScreenSize,
    screenSize,
    setToScrollSideMenu,
  } = useStateContext();

  const [heightMenu, setHeightMenu] = useState(0);
  const [scrollY, setScrollY] = useState(0);
  const paretnRef = useRef();
  const sideTop = useRef();

  useEffect(() => {
    const handleRezise = () => setScreenSize(window.innerWidth);
    window.addEventListener("resize", handleRezise);

    handleRezise();
    return () => window.removeEventListener("resize", handleRezise);
  }, []);

  useEffect(() => {
    const onPageLoad = () => {
      setHeightMenu(paretnRef.current.scrollHeight);
      setToScrollSideMenu(sideTop.current.offsetTop);
    };

    if (document.readyState === "complete") {
      onPageLoad();
    } else {
      window.addEventListener("load", onPageLoad);
    }
    return () => {
      window.removeEventListener("load", onPageLoad);
    };
  }, []);

  useEffect(() => {
    const detectarScroll = () => setScrollY(window.pageYOffset);
    window.addEventListener("scroll", detectarScroll);

    if (screenSize <= 1024) {
      if (window.scrollY > 50) {
        setmobileActiveMAuto(false);
      } else {
        setmobileActiveMAuto(true);
      }
    } else {
      !mobileActiveMAuto && setmobileActiveMAuto(true);
    }
  }, [scrollY, screenSize]);

  const mobileActive = () => {
    screenSize <= 1024 && setmobileActiveM(!mobileActiveM);
  };

  return (
    <div
      ref={sideTop}
      className="w-full lg:w-5/12 z-10 flex flex-col sticky top-2 "
    >
      <div className="sticky top-7">
        <div
          onClick={mobileActive}
          className="w-full  h-14  flex items-center bg-[#0082fd] justify-between "
        >
          <h4 className="uppercase font-bold ml-3 text-xl text-[#fede01] ">
            menú pkaz
          </h4>

          <img src={TopMenu} className="relative top-5 md:top-0" alt="" />
        </div>
        <div
          ref={paretnRef}
          style={
            mobileActiveMAuto || mobileActiveM
              ? { height: heightMenu + "px" }
              : { height: "0px" }
          }
          className={`w-full overflow-hidden duration-500 bg-white/95 lg:bg-gray-500/10 ${
            mobileActiveMAuto || mobileActiveM ? "pb-2" : "pb-0"
          }`}
        >
          <div
            onClick={() => handleActive(1)}
            className={`w-full px-2 pt-1 cursor-pointer duration-500 ${
              isActiveTab === 1 && "bg-red-header"
            }`}
          >
            <span className="gap-1 flex justify-start items-center border-b border-gray-400/50 hover:border-red-header">
              <img
                src={isActiveTab === 1 ? EntradasWhite : Entradas}
                className="w-8 h-8"
                alt=""
              />
              <b
                className={`text-sm ${
                  isActiveTab === 1 ? "text-white" : "text-gray-800"
                }`}
              >
                Entradas
              </b>
            </span>
          </div>
          <div
            onClick={() => handleActive(2)}
            className={`w-full px-2 pt-1 cursor-pointer duration-500 ${
              isActiveTab === 2 && "bg-red-header"
            }`}
          >
            <span className="border-b gap-1 flex justify-start items-center border-gray-400/50 hover:border-red-header">
              <img
                src={isActiveTab === 2 ? BigDogWhite : BigDog}
                className="w-8 h-8"
                alt=""
              />
              <b
                className={`text-sm ${
                  isActiveTab === 2 ? "text-white" : "text-gray-800"
                }`}
              >
                Big Dogs
              </b>
            </span>
          </div>
          <div
            onClick={() => handleActive(3)}
            className={`w-full px-2 pt-1 cursor-pointer duration-500 ${
              isActiveTab === 3 && "bg-red-header"
            }`}
          >
            <span className="border-b gap-1 flex justify-start items-center border-gray-400/50 hover:border-red-header">
              <img
                src={isActiveTab === 3 ? BurgerWhite : Burger}
                className="w-8 h-8"
                alt=""
              />
              <b
                className={`text-sm ${
                  isActiveTab === 3 ? "text-white" : "text-gray-800"
                }`}
              >
                Hamburguesas
              </b>
            </span>
          </div>
          <div
            onClick={() => handleActive(4)}
            className={`w-full px-2 pt-1 cursor-pointer duration-500 ${
              isActiveTab === 4 && "bg-red-header"
            }`}
          >
            <span className="border-b gap-1 flex justify-start items-center border-gray-400/50 hover:border-red-header">
              <img
                src={isActiveTab === 4 ? PizzaWhite : Pizza}
                className="w-8 h-8"
                alt=""
              />
              <b
                className={`text-sm ${
                  isActiveTab === 4 ? "text-white" : "text-gray-800"
                }`}
              >
                Pizzas
              </b>
            </span>
          </div>
          <div
            onClick={() => handleActive(5)}
            className={`w-full px-2 pt-1 cursor-pointer duration-500 ${
              isActiveTab === 5 && "bg-red-header"
            }`}
          >
            <span className="border-b gap-1 flex justify-start items-center border-gray-400/50 hover:border-red-header">
              <img
                src={isActiveTab === 5 ? PostresWhite : Postres}
                className="w-8 h-8"
                alt=""
              />
              <b
                className={`text-sm ${
                  isActiveTab === 5 ? "text-white" : "text-gray-800"
                }`}
              >
                Postres
              </b>
            </span>
          </div>
          <div
            onClick={() => handleActive(6)}
            className={`w-full px-2 pt-1 cursor-pointer duration-500 ${
              isActiveTab === 6 && "bg-red-header"
            }`}
          >
            <span className="border-b gap-1 flex justify-start items-center border-gray-400/50 hover:border-red-header">
              <img
                src={isActiveTab === 6 ? BebidasWhite : Bebidas}
                className="w-8 h-8"
                alt=""
              />
              <b
                className={`text-sm ${
                  isActiveTab === 6 ? "text-white" : "text-gray-800"
                }`}
              >
                Bebidas frías
              </b>
            </span>
          </div>
          <div
            onClick={() => handleActive(7)}
            className={`w-full px-2 pt-1 cursor-pointer duration-500 ${
              isActiveTab === 7 && "bg-red-header"
            }`}
          >
            <span className="border-b gap-1 flex justify-start items-center border-gray-400/50 hover:border-red-header">
              <img
                src={isActiveTab === 7 ? CofeWhite : Cofe}
                className="w-8 h-8"
                alt=""
              />
              <b
                className={`text-sm ${
                  isActiveTab === 7 ? "text-white" : "text-gray-800"
                }`}
              >
                Bebidas calientes
              </b>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideMenu;
