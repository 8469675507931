import React, { useEffect, useRef } from "react";
import { useStateContext } from "../context/ContextProvider";
import Alitas from "../data/promo_alitas.png";
import Helado from "../data/promo_helado.png";

const Promociones = () => {
  const { setPromociones } = useStateContext();
  const Promo = useRef();
  useEffect(() => {
    const onPageLoad = () => {
      const offsetT = Promo.current.offsetTop;
      setPromociones(offsetT);
    };

    if (document.readyState === "complete") {
      onPageLoad();
    } else {
      window.addEventListener("load", onPageLoad);
    }
    return () => {
      window.removeEventListener("load", onPageLoad);
    };
  }, []);

  return (
    <div id="promociones" ref={Promo} className="grid md:grid-cols-2">
      <div className="group overflow-hidden  relative">
        <span className="w-full h-full absolute bg-black/30 duration-500 group-hover:translate-x-full "></span>
        <img src={Alitas} alt="" className="w-full" />
      </div>
      <div className="group overflow-hidden  relative">
        <span className="w-full h-full absolute bg-black/30 duration-500 group-hover:-translate-x-full "></span>
        <img src={Helado} alt="" className="w-full" />
      </div>
    </div>
  );
};

export default Promociones;
