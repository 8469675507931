import { HashRouter, Route, Routes } from "react-router-dom";
import { Restaurante, SalonFiestas, ScrollToTop } from "./components";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./App.css";
import Home from "./pages/Home";
import AvisoPrivacidad from "./pages/AvisoPrivacidad";
import { useEffect } from "react";
import ReactGA from "react-ga";

function App() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  return (
    <div className="w-full">
      <HashRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/aviso-de-privacidad" element={<AvisoPrivacidad />} />
        </Routes>
        <Restaurante />
        <SalonFiestas />
        <ScrollToTop />
      </HashRouter>
    </div>
  );
}

export default App;
