import { red } from "@mui/material/colors";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";

const ButtonBuble = ({
  text,
  bubleColor,
  btnColor,
  border,
  borderC,
  textC,
  rounded,
  hoverColor,
  hoverBorderColor,
  onClick,
}) => {
  const [borderB, setBorderB] = useState(false);
  const [isHover, setIsHover] = useState(false);

  const handleMouseEnter = () => {
    setIsHover(true);
  };
  const handleMouseLeave = () => {
    setIsHover(false);
  };
  useEffect(() => {
    border && setBorderB(true);
  }, [border]);

  return (
    <button
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={
        isHover
          ? {
              color: hoverColor,
              backgroundColor: btnColor,
              borderColor: hoverBorderColor,
              borderRadius: rounded,
            }
          : {
              color: textC,
              backgroundColor: btnColor,
              borderColor: borderC,
              borderRadius: rounded,
            }
      }
      onClick={onClick}
      className={`${
        borderB && "border"
      } shadow-sm shadow-black group relative px-4 duration-300 py-2
       font-bold overflow-hidden`}
      to="/restaurante"
    >
      <p className="z-10 relative">{text}</p>
      <span
        style={{ backgroundColor: bubleColor }}
        className="w-120 h-44 absolute top-0 
        duration-300 -left-120 group-hover:-left-1 rounded-full
        group-hover:-translate-y-1/3"
      ></span>
    </button>
  );
};

export default ButtonBuble;
