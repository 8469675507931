import React from "react";
import { NavLink } from "react-router-dom";
import navLogo from "../data/logo_header.png";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import CloseIcon from "@mui/icons-material/Close";
import { useStateContext } from "../context/ContextProvider";
import ResponseNavSalon from "./ResponseNavSalon";

const NavBarSalon = () => {
  const { isOpen, setIsOpen, bgHeaders } = useStateContext();
  return (
    <>
      <div
        className={`${
          bgHeaders ? "bg-TopHeaderBg bg-cover" : ""
        } w-full absolute h-36 z-10`}
      >
        <div className="text-white hidden md:flex gap-8 justify-center items-center p-2">
          <NavLink to="/salon-de-fiestas">
            <img
              src={navLogo}
              alt="navlogo"
              className="w-40 animate-wiggle hover:animate-rapidWiggle"
            />
          </NavLink>
          <NavLink
            to="/salon-de-fiestas"
            style={({ isActive }) => ({
              color: isActive ? "#fede01" : "",
            })}
            className="text-lg font-bold hover:text-[#fede01]"
          >
            <span className="uppercase">inicio</span>
          </NavLink>
          <NavLink
            to="/paquetes"
            style={({ isActive }) => ({
              color: isActive ? "#fede01" : "",
            })}
            className="text-lg font-bold hover:text-[#fede01]"
          >
            <span className="uppercase">paquetes</span>
          </NavLink>
          <NavLink
            to="/galeria"
            style={({ isActive }) => ({
              color: isActive ? "#fede01" : "",
            })}
            className="text-lg font-bold hover:text-[#fede01]"
          >
            <span className="uppercase">galería</span>
          </NavLink>
          <NavLink
            to="/restaurante"
            style={({ isActive }) => ({
              color: isActive ? "#fede01" : "",
            })}
            className="text-lg font-bold hover:text-[#fede01]"
          >
            <span className="uppercase">restaurante</span>
          </NavLink>
          <NavLink
            to="/contacto"
            style={({ isActive }) => ({
              color: isActive ? "#fede01" : "",
            })}
            className="text-lg font-bold hover:text-[#fede01]"
          >
            <span className="uppercase">contacto</span>
          </NavLink>
        </div>
        <div className="justify-between items-center flex md:hidden px-4 p-2">
          <NavLink to="/salon-de-fiestas">
            <img
              src={navLogo}
              alt="navlogo"
              className="w-40 animate-wiggle hover:animate-rapidWiggle"
            />
          </NavLink>

          {isOpen ? (
            <CloseIcon
              onClick={() => setIsOpen(!isOpen)}
              className="text-20 mr-3 cursor-pointer text-white hover:rotate-90"
            />
          ) : (
            <MenuOutlinedIcon
              onClick={() => setIsOpen(!isOpen)}
              className="text-20 mr-3 cursor-pointer text-white"
            />
          )}
        </div>
      </div>
      <ResponseNavSalon />
    </>
  );
};

export default NavBarSalon;
