import React from "react";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { useState } from "react";

const BtnWhatsApp = ({ phone }) => {
  const [show, setShow] = useState(false);

  const showBtn = () => {
    if (window.scrollY >= 200) {
      setShow(true);
    } else setShow(false);
  };

  window.addEventListener("scroll", showBtn);
  return (
    <a
      href={`https://wa.me/${phone}`}
      target="_blank"
      rel="noopener noreferrer"
      className={`${
        show ? "opacity-100" : " opacity-0"
      } fixed flex p-2 bottom-24 group duration-500 overflow-hidden w-12 
    hover:w-60 right-5 cursor-pointer bg-green-600 rounded-full text-white z-50`}
    >
      <WhatsAppIcon className="relative right-0" style={{ fontSize: "2rem" }} />
      <div className="absolute duration-500 opacity-0 left-3 group-hover:left-1 group-hover:opacity-100 top-0 w-64 h-full flex justify-center items-center flex-nowrap">
        <p>¡Bienvenido a Pepepkaz!</p>
      </div>
    </a>
  );
};

export default BtnWhatsApp;
