import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Entradas from "../data/img_entradas.png";
import Hamburg from "../data/img_hamburguesas.png";
import BigDogs from "../data/img_bigdogs.png";
import TextBigDogs from "../data/tex_bigdogs.png";
import TextEntradas from "../data/tex_entradas.png";
import TextHamburg from "../data/tex_hamburgyesas.png";
import { useStateContext } from "../context/ContextProvider";

const BlueBanner = () => {
  const { setSobreNosotros, handleClickTop } = useStateContext();
  const sobreNosotros = useRef();
  useEffect(() => {
    const onPageLoad = () => {
      const offsetT = sobreNosotros.current.offsetTop;
      setSobreNosotros(offsetT);
    };

    if (document.readyState === "complete") {
      onPageLoad();
    } else {
      window.addEventListener("load", onPageLoad);
    }
    return () => {
      window.removeEventListener("load", onPageLoad);
    };
  }, []);
  return (
    <div
      id="sobreNosotros"
      ref={sobreNosotros}
      className="w-full grid lg:grid-cols-3 bg-bannerOrnamentsBlueB gap-8 px-8 py-12  "
    >
      <Link
        to="/menu"
        onClick={() => handleClickTop(1)}
        className="w-full justify-center items-center flex flex-col hover:scale-105 group duration-500"
      >
        <img
          className="w-8/12 sm:w-5/12 lg:w-8/12 group-hover:animate-wiggleSlow"
          src={TextEntradas}
          alt=""
        />
        <img
          className="w-8/12 sm:w-5/12 lg:w-8/12 group-hover:animate-wiggle"
          src={Entradas}
          alt=""
        />
        <b className="text-white px-4 text-center relative lg:top-2 group-hover:animate-wiggleSlow">
          Comparte lo mejor de nuestras entradas en familia
        </b>
      </Link>
      <Link
        to="/menu"
        onClick={() => handleClickTop(3)}
        className="w-full justify-center items-center flex flex-col hover:scale-105 group duration-500"
      >
        <img
          className="w-11/12 sm:w-8/12 lg:w-11/12 relative lg:top-2 group-hover:animate-wiggleSlow"
          src={TextHamburg}
          alt=""
        />
        <img
          className="w-8/12 sm:w-5/12 lg:w-9/12 group-hover:animate-wiggle"
          src={Hamburg}
          alt=""
        />
        <b className="text-white px-4 text-center  group-hover:animate-wiggleSlow">
          Comparte lo mejor de nuestras hamburguesas en familia
        </b>
      </Link>
      <Link
        to="/menu"
        onClick={() => handleClickTop(2)}
        className="w-full justify-center items-center flex flex-col hover:scale-105 group duration-500"
      >
        <img
          className="w-8/12 sm:w-5/12 lg:w-8/12 relative lg:-top-4 group-hover:animate-wiggleSlow"
          src={TextBigDogs}
          alt=""
        />
        <img
          className="w-8/12 sm:w-5/12 lg:w-8/12 relative lg:-top-6 group-hover:animate-wiggle"
          src={BigDogs}
          alt=""
        />
        <b className="text-white px-4 text-center lg:whitespace-nowrap  relative lg:-top-2 group-hover:animate-wiggleSlow">
          Van acompañados con una orden de papas
        </b>
      </Link>
    </div>
  );
};

export default BlueBanner;
