import React from "react";
import { useEffect } from "react";
import {
  BlueBanner,
  BtnWhatsApp,
  Footer,
  NavBar,
  Promociones,
  RedBanner,
  Siguenos,
  TopHeader,
  YellowBanner,
} from "../components";
import { useStateContext } from "../context/ContextProvider";

const Index = () => {
  const { setBgHeaders } = useStateContext();
  useEffect(() => {
    setBgHeaders(false);
  }, []);
  useEffect(() => {
    document.title = "Restaurante de Pepepkaz";
  }, []);
  return (
    <>
      <TopHeader phone={+525541296373} textWhatsApp="55 4129 6373" />
      <NavBar />
      <RedBanner />
      <BlueBanner />
      <YellowBanner />
      <Promociones />
      <Siguenos />
      <Footer />
      <BtnWhatsApp phone={+525541296373} />
    </>
  );
};

export default Index;
